<template>
  <div id="myList" class="myList">
    <ul>
      <li v-for="item in list" :key="item.id">
        <el-popover placement="top">
          <p>确定删除吗？</p>
          <div style="text-align: right; margin: 10px 0 0">
            <el-button type="primary" size="mini" @click="deleteCollect(item.productId)">确定</el-button>
          </div>
          <i class="el-icon-close delete" slot="reference" v-show="isDelete"></i>
        </el-popover>
        <router-link :to="{ path: '/goods/details', query: { productID: item.good.id } }">
          <div class="image">
            <img v-lazy="item.good.picUrl" alt class="img" />
          </div>
          <div class="box">
                <h2>{{item.good.name}}</h2>
                <div class="price">￥{{item.good.retailPrice}}</div>
            <!-- <h3>{{item.counterPrice}}</h3>
                <p>
                  <span>{{item.retailPrice}}元</span>
                  <span
                    v-show="item.productPrice != item.productSellingPrice"
                    class="del"
                  >{{item.counterPrice}}元</span>
                </p> -->
            <div class="bottom">
                    <div class="sales">销售量：{{item.orderGoodSold}}</div>
                    <div class="commentCount">评论：{{item.commentGoodSold}}</div>
              <div class="collect"><img src="" alt=""></div>
            </div>
          </div>

        </router-link>
      </li>
      <!-- <li v-show="isMore && list.length>=1" id="more">
          <router-link :to="{ path: '/goods', query: {categoryID:categoryID} }">
            浏览更多
            <i class="el-icon-d-arrow-right"></i>
          </router-link>
        </li> -->
    </ul>
  </div>
</template>
<script>
export default {
  name: "MyList",
  // list为父组件传过来的商品列表
  // isMore为是否显示“浏览更多”
  props: ["list", "isMore", "isDelete"],
  data() {
    return {};
  },
  computed: {
    // 通过list获取当前显示的商品的分类ID，用于“浏览更多”链接的参数
    categoryID: function () {
      let categoryID = [];
      if (this.list != "") {
        for (let i = 0; i < this.list.length; i++) {
          const id = this.list[i].category_id;
          if (!categoryID.includes(id)) {
            categoryID.push(id);
          }
        }
      }
      return categoryID;
    }
  },
  methods: {
    deleteCollect(product_id) {
      this.$axios
        .delete("/api/collect/user/" + product_id + "/" + this.$store.getters.getUser.userId)
        .then(res => {
          switch (res.data.code) {
            case "001":
              // 删除成功
              // 删除删除列表中的该商品信息
              for (let i = 0; i < this.list.length; i++) {
                const temp = this.list[i];
                if (temp.productId == product_id) {
                  this.list.splice(i, 1);
                }
              }
              // 提示删除成功信息
              this.notifySucceed(res.data.msg);
              break;
            default:
              // 提示删除失败信息
              this.notifyError(res.data.msg);
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    refresh() {
      location.reload()
    }
  }
};
</script>
<style scoped>
a {
  text-decoration: none
}

.myList ul {
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

}

.myList ul li {
  z-index: 1;
  float: left;
  width: 30%;
  padding: .625rem
    /* 10/16 */
    0;
  margin: 0 0 .90625rem
    /* 14.5/16 */
    .85625rem
    /* 13.7/16 */
  ;
  background-color: white;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  box-sizing: border-box;
  border: .0625rem
    /* 1/16 */
    solid #BBBBBB;
  border-radius: .3125rem
    /* 5/16 */
  ;
  background-color: rgba(255, 255, 255, 1);
}

.myList ul li:hover {
  z-index: 2;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  background-color: #446C37;
  color: white;
}

.myList ul li:hover .box div {
  z-index: 2;
  color: white;
}

.myList ul li:hover .box h2 {
  z-index: 2;
  color: white;
}

.myList ul li img {
  width: 10rem
    /* 160/16 */
  ;
  display: block;
  margin: 0 auto;
}

.myList ul li h2 {
  /*margin: 25px 10px 0;*/
  font-size: .875rem
    /* 14/16 */
  ;
  font-weight: 400;
  color: rgba(17, 17, 17, 1);
  ;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 .875rem
    /* 14/16 */
  ;
}

.myList ul li p .del {
  margin-left: 0.5em;
  color: #b0b0b0;
  text-decoration: line-through;
}

.myList #more {
  text-align: center;
  line-height: 280px;
}

.myList #more a {
  font-size: 18px;
  color: #333;
}

.myList #more a:hover {
  color: #ff6700;
}

.myList ul li .delete {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.myList ul li:hover .delete {
  display: block
}

.myList ul li .delete:hover {
  color: #ff6700;
}

.img {
  width: 10rem
    /* 160/16 */
  ;
  height: 10rem
    /* 160/16 */
  ;
}

.image {
  width: 100%;
  background-color: white;
  border-radius: .3125rem
    /* 5/16 */
    .3125rem 0 0;
  margin-top: -.625rem
    /* 10/16 */
  ;
}

.box {
  margin: 0;
  width: 100%;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  bottom: 0;
}

.bottom .sales {
  width: 50%;
  padding: 0 0 0 .875rem
    /* 14/16 */
  ;
  color: rgba(17, 17, 17, 1);
}

.bottom .commentCount {
  width: 35%;
  padding: 0 .875rem
    /* 14/16 */
    0 0;
  color: rgba(17, 17, 17, 1);
}

.bottom .collect {
  width: 15%;
}

.price {
  padding: 0 0 0 .875rem
    /* 14/16 */
  ;
  color: rgba(234, 0, 0, 0.88);
  font-size: .8125rem
    /* 13/16 */
  ;
}</style>