import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI, { Loading } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import Swiper from "swiper/dist/idangerous.swiper.min.js";

import 'swiper/dist/idangerous.swiper.css'


import BaiduMap from 'vue-baidu-map'

import VueLazyload from 'vue-lazyload'


Vue.use(BaiduMap, { ak: '2BuDXmLreqiZXv2HhLqWvN8qhuzqOVy2' })

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

const errorPicture = require('@/assets/loading.jpg')
const loadingPicture = require('@/assets/loading.jpg')
Vue.use(VideoPlayer)
Vue.use(VueLazyload, {
  error: errorPicture,
  loading: loadingPicture
})
import dayjs from "dayjs"
Vue.prototype.$dayjs = dayjs;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
