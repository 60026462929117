import request from '@/utils/request'

export function loginByQRCode (code, state) {
  const data = {
    code,
    state
  }
  return request({
    url: '/wx/auth/QRCodeLogin',
    method: 'post',
    data
  })
}

export function loginByUsername (username, password, code) {
  const data = {
    username,
    password,
    code
  }
  return request({
    url: '/wx/auth/login',
    method: 'post',
    data
  })
}

export function listIndex (query) {
  return request({
    url: '/wx/home/index',
    method: 'get',
    params: query
  })
}

export function readDetail (query) {
  return request({
    url: '/wx/goods/detail',
    method: 'get',
    params: query
  })
}

export function goodsRelated (query) {
  return request({
    url: '/pc/goods/related',
    method: 'get',
    params: query
  })
}

export function brandList (query) {
  return request({
    url: '/wx/brand/list',
    method: 'get',
    params: query
  })
}
export function goodsList (query) {
  return request({
    url: '/pc/goods/list',
    method: 'get',
    params: query
  })
}
export function categoryList (query) {
  return request({
    url: '/wx/catalog/index',
    method: 'get',
    params: query
  })
}
export function categoryL2 (query) {
  return request({
    url: '/pc/category/l2',
    method: 'get',
    params: query
  })
}
export function commentList (query) {
  return request({
    url: '/wx/comment/list',
    method: 'get',
    params: query
  })
}
export function commentCount (query) {
  return request({
    url: '/wx/comment/count',
    method: 'get',
    params: query
  })
}
export function searchIndex (query) {
  return request({
    url: '/wx/search/index',
    method: 'get',
    params: query
  })
}
export function cartFastAdd (query) {
  return request({
    url: '/wx/cart/fastadd',
    method: 'post',
    data: query
  })
}
export function cartAdd (query) {
  return request({
    url: '/wx/cart/add',
    method: 'post',
    data: query
  })
}
export function firstList (query) {
  return request({
    url: '/pc/goods/firstList',
    method: 'get',
    params: query
  })
}
export function orderList (query) {
  return request({
    url: '/wx/order/list',
    method: 'get',
    params: query
  })
}
export function addressList (query) {
  return request({
    url: '/wx/address/list',
    method: 'get',
    params: query
  })
}
export function delAddress (query) {
  return request({
    url: '/wx/address/delete',
    method: 'post',
    data: query
  })
}
export function addAddress (query) {
  return request({
    url: '/wx/address/save',
    method: 'post',
    data: query
  })
}
export function addressDetail (query) {
  return request({
    url: '/wx/address/detail',
    method: 'get',
    params: query
  })
}
export function orderDetail (query) {
  return request({
    url: '/wx/order/detail',
    method: 'get',
    params: query
  })
}
export function orderCancel (query) {
  return request({
    url: '/wx/order/cancel',
    method: 'post',
    data: query
  })
}
export function orderRefund (query) {
  return request({
    url: '/wx/order/refund',
    method: 'post',
    data: query
  })
}
export function orderDelete (query) {
  return request({
    url: '/wx/order/delete',
    method: 'post',
    data: query
  })
}
export function orderConfirm (query) {
  return request({
    url: '/wx/order/confirm',
    method: 'post',
    data: query
  })
}
export function orderGoods (query) {
  return request({
    url: '/wx/order/goods',
    method: 'get',
    params: query
  })
}
export function orderComment (query) {
  return request({
    url: '/wx/order/comment',
    method: 'post',
    data: query
  })
}
export function goodComment (query) {
  return request({
    url: '/pc/comment/post',
    method: 'post',
    data: query
  })
}
export function orderPrepay (query) {
  return request({
    url: '/wx/order/prepay',
    method: 'post',
    data: query
  })
}
const CartList = '/wx/cart/index' // 获取购物车的数据
export function cartList (query) {
  return request({
    url: CartList,
    method: 'get',
    params: query
  })
}
const CartUpdate = '/wx/cart/update' // 更新购物车的商品
export function cartUpdate (data) {
  return request({
    url: CartUpdate,
    method: 'post',
    data
  })
}
const CartDelete = '/wx/cart/delete' // 删除购物车的商品
export function cartDelete (data) {
  return request({
    url: CartDelete,
    method: 'post',
    data
  })
}
const CartChecked = '/wx/cart/checked' // 选择或取消选择商品
export function cartChecked (data) {
  return request({
    url: CartChecked,
    method: 'post',
    data
  })
}
const CartGoodsCount = '/wx/cart/goodscount' // 获取购物车商品件数
export function cartGoodsCount () {
  return request({
    url: CartGoodsCount,
    method: 'get'
  })
}
const CartCheckout = '/wx/cart/checkout' // 下单前信息确认
export function cartCheckout (query) {
  return request({
    url: CartCheckout,
    method: 'get',
    params: query
  })
}
export function listNews (query) {
  return request({
    url: '/wx/news/list',
    method: 'get',
    params: query
  })
}
export function submitConsultingOnline (query) {
  return request({
    url: '/wx/hearingtest/create',
    method: 'post',
    data: query
  })
}
export function listVisitingConsult (query) {
  return request({
    url: '/pc/hearingconsult/officiallist',
    method: 'get',
    params: query
  })
}
export function submitMessage (query) {
  return request({
    url: '/pc/hearingconsult/officialAdd',
    method: 'post',
    data: query
  })
}

export function listTest (query) {
  return request({
    url: '/wx/hearingtest/list',
    method: 'get',
    params: query
  })
}
export function submitExpertConsulting (query) {
  return request({
    url: '/wx/experts/consult',
    method: 'post',
    data: query
  })
}
export function shopTableData (query) {
  return request({
    url: '/pc/shops/list',
    method: 'get',
    params: query
  })
}
export function collectAddOrDelete (query) {
  return request({
    url: '/wx/collect/addordelete',
    method: 'post',
    data: query
  })
}
export function collectList (query) {
  return request({
    url: '/wx/collect/list',
    method: 'get',
    params: query
  })
}
export function getExperts (query) {
  return request({
    url: 'pc/experts/list',
    method: 'get',
    params: query
  })
}
export function getShops (query) {
  return request({
    url: 'pc/shops/list',
    method: 'get',
    params: query
  })
}
export function sortList (query) {
  return request({
    url: 'pc/goods/sortList',
    method: 'get',
    params: query
  })
}
export function deafHearingList (query) {
  return request({
    url: 'pc/hearingabout/deafhealinglist',
    method: 'get',
    params: query
  })
}
export function aidsKnowledgeList (query) {
  return request({
    url: '/pc/hearingabout/aidsknowledgelist',
    method: 'get',
    params: query
  })
}
export function hearingKnowledgeList (query) {
  return request({
    url: 'pc/hearingabout/hearingknowledgelist',
    method: 'get',
    params: query
  })
}
export function getStoreList (query) {
  return request({
    url: 'pc/shops/list',
    method: 'get',
    params: query
  })
}

export function getQAList (query) {
  return request({
    url: 'pc/hearingabout/qalist',
    method: 'get',
    params: query
  })
}

export function submitQuestion (query) {
  return request({
    url: 'pc/hearingabout/qaAdd',
    method: 'post',
    data: query
  })
}

export function getProfessionalList (query) {
  return request({
    url: 'pc/servicesupport/professionallist',
    method: 'get',
    params: query
  })
}

export function getTypicalList (query) {
  return request({
    url: 'pc/hearingabout/typicallist',
    method: 'get',
    params: query
  })
}

export function getComments (query) {
  return request({
    url: '/pc/comment/list',
    method: 'get',
    params: query
  })
}
