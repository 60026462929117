import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
    // component: HomeView
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import('../views/Mall.vue')
  },
  {
    path: '/goods/details',
    name: 'details',
    component: () => import('../views/shop/Detail.vue')
  },
  {
    path: '/center/center',
    name: 'center',
    component: () => import('../views/Center/Center.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/consultingOnline',
    name: 'consultingOnline',
    component: () => import('../views/consultingOnline/ConsultingOnline.vue')
  },
  {
    path: '/expertConsult',
    name: 'expertConsult',
    component: () => import('../views/expertConsult/ExpertConsult.vue')
  },
  {
    path: '/listenTest',
    name: 'listenTest',
    component: () => import('../views/listenTest/ListenTest.vue')
  },
  {
    path: '/visitingService',
    name: 'visitingService',
    component: () => import('../views/visitingService/VisitingService.vue')
  },
  {
    path: '/visitingConsult',
    name: 'visitingConsult',
    component: () => import('../views/visitingConsult/visitingConsult.vue')
  },
  {
    path: '/TestDetail',
    name: 'TestDetail',
    component: () => import('../views/listenTest/TestDetail.vue')
  },
  {
    path: '/TestResult',
    name: 'TestResult',
    component: () => import('../views/listenTest/TestResult.vue')
  },
  {
    path: '/ShopDetail',
    name: 'ShopDetail',
    component: () => import('../views/ShopDetail.vue')
  },
  {
    path: '/ServiceNet',
    name: 'ServiceNet',
    component: () => import('../views/ServiceNet.vue')
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('../views/News.vue')
  },
  {
    path: '/NewsDetail',
    name: 'NewsDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/HuierCulture',
    name: 'HuierCulture',
    component: () => import('../views/aboutHuier/HuierCulture.vue')
  },
  {
    path: '/HuierProfile',
    name: 'HuierProfile',
    component: () => import('../views/aboutHuier/HuierProfile.vue')
  },
  {
    path: '/enterpriseHonor',
    name: 'enterpriseHonor',
    component: () => import('../views/aboutHuier/enterpriseHonor.vue')
  },
  {
    path: '/enterpriseLicense',
    name: 'enterpriseLicense',
    component: () => import('../views/aboutHuier/enterpriseLicense.vue')
  },
  {
    path: '/HuierElegantDemeanor',
    name: 'HuierElegantDemeanor',
    component: () => import('../views/aboutHuier/HuierElegantDemeanor.vue')
  },
  {
    path: '/scientificPayoffs',
    name: 'scientificPayoffs',
    component: () => import('../views/aboutHuier/scientificPayoffs.vue')
  },
  {
    path: '/Order',
    name: 'Order',
    component: () => import('../views/shop/Order.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle1',
    name: 'HuierElegantDemeanorArticle1',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle1.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle2',
    name: 'HuierElegantDemeanorArticle2',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle2.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle3',
    name: 'HuierElegantDemeanorArticle3',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle3.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle4',
    name: 'HuierElegantDemeanorArticle4',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle4.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle5',
    name: 'HuierElegantDemeanorArticle5',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle5.vue')
  },
  {
    path: '/HuierElegantDemeanorArticle6',
    name: 'HuierElegantDemeanorArticle6',
    component: () => import('../views/aboutHuier/HuierElegantDemeanorArticle6.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/hearingKnowledge',
    name: 'hearingKnowledge',
    component: () => import('../views/understanding/hearingKnowledge.vue')
  },
  {
    path: '/deafKidsRecovery',
    name: 'deafKidsRecovery',
    component: () => import('../views/understanding/deafKidsRecovery.vue')
  },
  {
    path: '/aidsCommonSense',
    name: 'aidsCommonSense',
    component: () => import('../views/understanding/aidsCommonSense.vue')
  },
  {
    path: '/QuestionAndAnswer',
    name: 'QuestionAndAnswer',
    component: () => import('../views/understanding/QuestionAndAnswer.vue')
  },
  {
    path: '/classicCase',
    name: 'classicCase',
    component: () => import('../views/understanding/classicCase.vue')
  },
  {
    path: '/UnderstandingDetail',
    name: 'UnderstandingDetail',
    component: () => import('../views/understanding/UnderstandingDetail.vue')
  },
  {
    path: '/listeningCenter',
    name: 'listeningCenter',
    component: () => import('../views/support/listeningCenter.vue')
  },
  {
    path: '/trainingSetup',
    name: 'trainingSetup',
    component: () => import('../views/support/trainingSetup.vue')
  },
  {
    path: '/profServiceProject',
    name: 'profServiceProject',
    component: () => import('../views/support/profServiceProject.vue')
  },
  {
    path: '/fixing',
    name: 'fixing',
    component: () => import('../views/support/fixing.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/support/download.vue')
  },
  {
    path: '/profServiceProjectDetail',
    name: 'profServiceProjectDetail',
    component: () => import('../views/profServiceProjectDetail.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/support/contactUs.vue')
  }, {
    path: '/customerAppraisement',
    name: 'customerAppraisement',
    component: () => import('../views/customerAppraisement/customerAppraisement.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
