<template>
  <div class="container">
    <div class="homePage">
      <div class="pageLeft">
        <div class="swiperWrap">
          <div class="swiper">
            <div class="banner">
              <el-carousel height="15.125rem" @change="onChange" :autoplay="autoplay" :interval="interval"
                :initial-index="initialIndex">
                <el-carousel-item v-for="item in bannerList" :key="item.id" class="videoBox">
                  <!-- <video v-lazy="item.url" controls="controls" v-if="item.isVideo==true" class="bannerVideo" ref="videoPlayer" id="video" :options="playerOptions"></video> -->
                  <video-player style="height: 15.125rem;" v-if="item.isVideo == true" ref="videoPlayer"
                    class="video-player vjs-custom-skin myVideo" :playsinline="true" :options="playerOptions"
                    @ended="onPlayerEnded($event)" @loadeddata="onPlayerLoadeddata($event)" />
                  <img v-else v-lazy="item.url" alt="" class="img" @click="goGoodsDetail(item)"
                    :class="item.link.length > 0 ? 'active' : ''">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <div class="leftTop">
          <div class="icons">
            <el-card class="box-card" style="background-color: rgba(255, 255, 255, 1);">
              <router-link :to="{ path: '/listenTest' }" style="width:25%">
                <div class="iconBox">
                  <div class="iconImg">
                    <img src="../assets/echometer@2x.png" alt="">
                  </div>
                  <div class="iconTxt">听力测试</div>
                </div>
              </router-link>
              <router-link :to="{ path: '/expertConsult' }" style="width:25%">
                <div class="iconBox">
                  <div class="iconImg">
                    <img src="../assets/consult@2x.png" alt="">
                  </div>
                  <div class="iconTxt">顾问咨询</div>
                </div>
              </router-link>
              <router-link :to="{ path: '/visitingService' }" style="width:25%">
                <div class="iconBox">
                  <div class="iconImg">
                    <img src="../assets/door@2x.png" alt="">
                  </div>
                  <div class="iconTxt">上门服务</div>
                </div>
              </router-link>
              <router-link :to="{ path: '/consultingOnline' }" style="width:25%">
                <div class="iconBox" style="border:none">
                  <div class="iconImg">
                    <img src="../assets//edit@2x.png" alt="">
                  </div>
                  <div class="iconTxt">在线咨询</div>
                </div>
              </router-link>
            </el-card>
          </div>
          <div class="consultingOnline" @click="showConsultingOnline = true">
            <div class="consulting">
              <div class="consultingIcon">
                <img src="../assets/write@2x.png" alt="">
              </div>
              <div class="consultingTxt">
                在线咨询
              </div>
              <div class="consultingArrow">
                <img src="../assets/iconPark-double-right@2x.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="leftBottom">
          <div class="showExpert">
            <el-card class="expertCard">
              <div slot="header" class="clearfix">
                <span>专家顾问</span>
              </div>
              <div class="expertSwiper">
                <el-carousel height="11.25rem" indicator-position="none">
                  <el-carousel-item v-for="item, index in expertsList" :key="index">
                    <div class="experts">
                      <div class="expertItem" v-for="expert, index in item" :key="index">
                        <div class="expert" v-if="expert">
                          <div class="expertImg">
                            <img v-lazy="expert.url" alt="">
                          </div>
                          <div class="expertName">{{ expert.name }}{{ expert.job }}</div>
                          <div class="expertConsult">
                            <div class="button" @click="consult(expert)">
                              <div class="buttonTxt">咨询</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-card>
          </div>
        </div>
      </div>
      <div class="pageRight">
        <div class="right">
          <div class="showNews">
            <el-card class="newsCard">
              <div class="newsSwiper">
                <div class="newsHeader">
                  <span class="clearfix headerNews">新闻资讯</span>
                  <span class="newsMore moreNews" @click="goNews">
                    更多
                    <img src="../assets/iconPark-double-right-green@2x.png" alt="">
                  </span>
                </div>
                <div class="part">
                  <div class="part1"></div>
                  <div class="part2"></div>
                  <div class="part3"></div>
                  <div class="part4"></div>
                </div>
                <el-carousel height="12.6875rem" indicator-position="none" direction="vertical">
                  <el-carousel-item v-for="item, index in newsList" :key="index">
                    <div class="news">
                      <div class="newsItem" v-for="news, index in item" :key="index">
                        <div class="newsIn" v-if="news">
                          <!-- <div class="newsLeft">
                            <img v-lazy="news.url" alt="">
                          </div> -->
                          <div class="newsRight">
                            <div class="centerTop">
                              <div class="newsTitle" @click="goDetail(news)">{{ news.title }}</div>
                              <!-- <div class="newsDate">
                                <img src="../assets/arcoDesign-schedule@2x.png" alt="">
                                {{ news.addTime }}
                              </div> -->
                              <div class="newsContent">{{ news.about }}</div>
                            </div>
                            <br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-card>
          </div>
        </div>
        <div class="leftCenter">
          <div class="showOrder">
            <el-card class="order-card">
              <div slot="header" class="clearfix">
                <span>实时动态</span>
              </div>
              <div class="orderSwiper">
                <!-- release版本的评论 -->
                <!-- <el-carousel height="4rem" direction="vertical" indicator-position="none">
                  <el-carousel-item v-for="item, index in commentList" :key="index">
                    <div class="item" v-for="comment, index in item" :key="index">
                      <div class="nickName">{{ comment.nickname }}</div>
                      <div class="order">评价了{{ comment.goodsName }}</div>
                      <div class="date">{{ comment.updateTime }}</div>
                    </div>
                  </el-carousel-item>
                </el-carousel> -->
                <el-carousel height="4rem" direction="vertical" indicator-position="none">
                  <el-carousel-item v-for="item, index in ordersList" :key="index">
                    <div class="item" v-for="order, index in item" :key="index">
                      <div class="nickName">{{ order.nickname }}</div>
                      <div class="order">下单{{ order.goodsName }}</div>
                      <div class="date">{{ order.addTime }}</div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-card>
          </div>
        </div>
        <div class="rightBottom">
          <div class="showAddress">
            <el-card class="address-card">
              <div slot="header" class="newsHeader">
                <span class="clearfix">服务网络</span>
                <span class="newsMore" @click="goBack" v-if="showBack">
                  返回
                  <!-- <img src="../assets/iconPark-double-right-green@2x.png" alt=""> -->
                </span>
              </div>
              <div class="addressSwiper">
                <!-- <div class="addressLeft">
                  <img src="../assets/serviceNetwork@2x.png" alt="">
                </div> -->
                <el-carousel height="8rem" direction="vertical">
                  <el-carousel-item v-for="item, index in provinceList" :key="index">
                    <div class="showProvinceList">
                      <div class="addressItem" v-for="shop, index in item" :key="index">
                        <div class="provinceItem" v-for="province, index in shop" :key="index">
                          <div :title="province.provinceName" class="shopName" @click="changeToCity(province)"
                            v-if="showBack == false">{{ province.provinceName }}</div>
                          <div :title="province.cityName" class="shopName" @click="filterByCity(province)" v-else>
                            {{ province.cityName }}</div>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
    <div class="showShop">
      <div class="shopNav">
        <div class="navIcon">
          <img src="@/assets/ear white@2x.png" alt="">
        </div>
        <div class="navDetail">
          <div class="cityItem" v-for="item, index in cityNavList" :key="index" @click="filterByCity(item)">
            <div class="cityIcon">
              <img v-lazy="item.icon" alt="">
            </div>
            <div class="cityName">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="shopData">
        <div class="shopDataList">
          <el-table :data="shopDataList" stripe style="width: 100%" :show-header="false">
            <el-table-column prop="url">
              <template slot-scope="scope">
                <el-image :src="thumbnail(scope.row.url)" :preview-src-list="toPreview(scope.row, scope.row.url)"
                  style="width: 80px; height: 40px" lazy />
              </template>
            </el-table-column>
            <el-table-column prop="name"></el-table-column>
            <el-table-column prop="address"></el-table-column>
            <el-table-column prop="tel"></el-table-column>
            <el-table-column prop="more">
              <template slot-scope="scope">
                <div class="moreImg" @click="goShopDetail(scope.row)">
                  <img src="@/assets/iconPark-world Copy@2x.png" alt="">
                  进入查看
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination" v-if="shopDataList.length > 0">
          <el-pagination background layout="total,prev, pager, next" :page-size="5" :total="shopDataTotal"
            @current-change="shopDataChange" :current-page="page" v-if="showPagination"></el-pagination>
        </div>
      </div>
    </div>
    <div class="showGoods">
      <div class="goodsTop">
        <div class="goodsLeft">
          <div class="showTitle">
            助听器推荐
          </div>
        </div>
        <div class="goodsRight">
          <div class="rightBox" @click="getNewGoods">新品上市</div>
          <div class="rightBox" @click="filterByName('儿童机')">儿童机</div>
          <div class="rightBox" @click="filterByName('经济型助听器')">经济型助听器</div>
          <div class="rightBox" @click="filterByName('耳鸣治疗型助听器')">耳鸣治疗型助听器</div>
          <div class="rightBox">
            <div class="searching">
              <el-input placeholder="Search" suffix-icon="el-icon-search" style="width:80%;"
                @keyup.enter.native="searchGoods" v-model="search"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="goodsCenter">
        <div class="centerLeft">
          <div class="classifyList">
            <div class="classify" @click="filterByCondition('orderGoodSold')">按助听器销量</div>
            <div class="classify" @click="filterByCondition('retail_price')">按助听器价格</div>
            <div class="classify" @click="filterByCondition('commentGoodSold')">按助听器评论</div>
          </div>
        </div>
        <div class="centerRight">
          <div class="showList">
            <div class="showMyList">
              <HomeList :list="list" v-if="list.length > 0"></HomeList>
              <div v-else class="none-product">抱歉没有找到相关的商品，请看看其他的商品</div>
            </div>
            <div class="pagination" v-if="list.length > 0">
              <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total"
                @current-change="currentChange"></el-pagination>
            </div>
          </div>

          <div class="showCompany">
            <div class="recommend">推荐品牌</div>
            <div class="showRecommend">
              <div class="showIcon" v-for="item, index in iconList" :key="index">
                <div class="iconItem">
                  <img v-lazy="item.pic" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="aboutUs">
      <div class="aboutLeft">
        <div class="aboutTitle">
          <div class="titleCN">惠耳简介</div>
          <div class="titleUS">/ ABOUT US</div>
        </div>
        <div class="aboutContent">
          <div class="content">
            惠耳听力是一家专业从事助听器研发、生产、验配与服务的高新技术企业，多年来为众多听障用户提供了优质的产品与服务，公司通过国际医疗器械质量管理体系认证。并在浙江、上海、广东、江苏、山东、湖北、河南、湖南、江西、安徽、四川、陕西、福建、天津、重庆、甘肃、河北、山西、广西、海南等20个省、直辖市开设了1000余家专业的听力连锁服务机构（截止到2022年7月），近2000名专业的服务人员为广大听障用户提供真诚的优质服务，赢得了广大听障者的信任与赞扬，取得了较高的社会效益。
          </div>
          <div class="moreContent">
            更多
            <img src="../assets/iconPark-double-right-green@2x.png" alt="">
          </div>
        </div>
        <div class="aboutDetail">
          <div class="aboutIcons">
            <div class="aboutBox" v-for="item,index in aboutList" :key="index" @click="goAbout(item.url)">
              <img v-lazy="item.pic" alt="">
              <div class="detailTitle">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutRight">
        <img src="../assets/about@3x.png" alt="">
      </div>
    </div> -->
    <!-- <div class="chainStore">
      <div class="shopTop">
        <div class="shopTitle">
          <div class="titleCN">连锁门店</div>
          <div class="titleUS">/ Chain store</div>
        </div>
        <div class="moreShop" @click="goServiceNet">
          查看更多
            <img src="../assets/iconPark-double-right-green@2x.png" alt="">
        </div>
      </div>
      <div class="showShop">
        <el-carousel height="25rem" indicator-position="none">
          <el-carousel-item v-for="item,index in shopList" :key="index">
            <div class="shops">
              <div class="shopItem" v-for="shop,index in item" :key="index">
                <div class="shop" v-if="shop">
                  <div class="shopImg" @click="goShopDetail(shop)">
                    <img v-lazy="shop.url" alt="">
                  </div>
                  <div class="shopConsult">
                    <el-button type="primary" @click="showConsultingOnline=true" size="medium" style="background:#FF9C01;border-radius: 8px;">咨询</el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="friendlyLink">
        <div class="linkTitle">
          友情链接
          <img src="@/assets/fas fa-chevron-circle-right@3x.png" alt="">
        </div>
        <div class="linkList">
          <div class="link" v-for="item,index in linkList" :key="index">
            <img src="@/assets/ear@3x.png" alt="">
            {{item}}
          </div>
        </div>
      </div>
    </div> -->
    <el-dialog width="60%" :visible.sync="showConsultingOnline" title="在线咨询" v-if="showConsultingOnline">
      <div class="wrap">
        <div class="wrapLeft">
          <el-form ref="form" :model="consultingData" label-width="100px" :rules="rules">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="consultingData.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="tel">
              <el-input v-model="consultingData.tel"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input v-model="consultingData.address"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input v-model="consultingData.note" type="textarea" autosize></el-input>
            </el-form-item>
            <el-form-item label="">
              温馨提示：带 “*” 为必填项
            </el-form-item>
          </el-form>
        </div>
        <div class="wrapRight">
          <div class="publicImg">
            <img src="../assets/public@3x.png" alt="">
          </div>
          <div class="publicContent">
            微信预约配机享受<span style="color:red">200</span>元优惠(仅限于全数字助听器)
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="reset">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="60%" :visible.sync="showConsult" title="专家咨询" v-if="showConsult">
      <div class="wrap">
        <div class="wrapLeft">
          <el-form ref="consultingForm" :model="consultingExpertData" label-width="140px" :rules="consultingExpertRules">
            <el-form-item label="用户姓名" prop="name">
              <el-input v-model="consultingExpertData.name"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="tel">
              <el-input v-model="consultingExpertData.tel"></el-input>
            </el-form-item>
            <el-form-item label="咨询高级顾问" prop="expert">
              <el-select v-model="consultingExpertData.expert" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="咨询时间" prop="consultTime">
              <el-date-picker v-model="consultingExpertData.consultTime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="听力损失时间" prop="hearinglossTime">
              <el-select v-model="consultingExpertData.hearinglossTime" placeholder="请选择">
                <el-option v-for="item, index in hearingTimeArr" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有无流脓或手术史" prop="isDisease">
              <el-select v-model="consultingExpertData.isDisease" placeholder="请选择">
                <el-option v-for="item, index in selectArr" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="耳聋家族及遗传病史" prop="isGenetic">
              <el-select v-model="consultingExpertData.isGenetic" placeholder="请选择">
                <el-option v-for="item, index in selectArr" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有无助听器使用史" prop="isUsed">
              <el-select v-model="consultingExpertData.isUsed" placeholder="请选择">
                <el-option v-for="item, index in selectArr" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所在地区" prop="address">
              <el-input v-model="consultingExpertData.address"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="note">
              <el-input v-model="consultingExpertData.note" type="textarea" autosize></el-input>
            </el-form-item>
            <el-form-item label="">
              温馨提示：带 “*” 为必填项
            </el-form-item>
          </el-form>
        </div>
        <div class="wrapRight">
          <div class="publicImg">
            <img src="@/assets/public@3x.png" alt="">
          </div>
          <div class="publicContent">
            微信预约配机享受<span style="color:red">200</span>元优惠(仅限于全数字助听器)
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetExpert">取消</el-button>
        <el-button type="primary" @click="submitExpert">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog width="60%" :visible.sync="showResult" title="专家咨询" v-if="showResult">
      <div class="wrap">
        <div class="wrapLeftResult">
          <div class="leftImg">
            <img src="@/assets/semiDesign-semi-icons-verify Copy@3x.png" alt="">
          </div>
          <div class="leftWord">提交成功，后续客服会与您联系</div>
        </div>
        <div class="wrapRight">
          <div class="publicImg">
            <img src="@/assets/public@3x.png" alt="">
          </div>
          <div class="publicContent">
            微信预约配机享受<span style="color:red">200</span>元优惠(仅限于全数字助听器)
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showResult = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- <remote-js src="http://myip.ipip.net/cityjson?ie=utf-8"></remote-js> -->
  </div>
</template>
<script>
import { getExperts, listIndex, listNews, submitConsultingOnline, submitExpertConsulting, goodsList, getShops, shopTableData, sortList } from '@/api/mall';
import dayjs from 'dayjs';
import HomeList from '@/components/HomeList.vue'
import { thumbnail, toPreview } from '@/utils/index'
import areaList from '../assets/area.json';
import myBMap from "@/api/bmap";
import 'video.js/dist/video-js.css'
// import {videoPlayer}  from 'vue-video-player'
import videoJS from "video.js";
export default {
  name: 'HomeView',
  components: {
    HomeList,
    // videoPlayer
  },
  data() {
    return {
      thumbnail,
      toPreview,
      areaList,
      bannerList: [],
      expertsList: [],
      newsList: [],
      brandList: [],
      categoryList: [],
      commentList: [],
      ordersList: [],
      total: 0, // 商品总量
      pageSize: 6, // 每页显示的商品数量
      currentPage: 1, //当前页码
      activeName: '',
      list: [],
      goodsQuery: {
        limit: 6,
        page: 1,
      },
      search: '',
      historyKeywordList: [],
      cartTotal: 0,
      categoryId: 1,
      shopsList: [],
      shopList: [],
      showConsultingOnline: false,
      consultingData: {
        address: '',
        name: '',
        note: '',
        gender: '',
        tel: '',
      },
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        tel: [{ required: true, message: '手机号不能为空', trigger: 'blur' }]
      },
      showConsult: false,
      showResult: false,
      consultingExpertData: {
        address: '',
        name: '',
        note: '',
        tel: '',
        consultTime: '',
        expertId: '',
        hearinglossTime: '',
        isDisease: '',
        isGenetic: '',
        isUsed: '',
      },
      consultingExpertRules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        tel: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        consultTime: [{ required: true, message: '咨询时间不能为空', trigger: 'blur' }],
        address: [{ required: true, message: '所在地区不能为空', trigger: 'blur' }],
      },
      hearingTimeArr: ['小于一年', '一到三年', '三到五年', '五年以上'],
      selectArr: ['是', '否'],
      options: [],
      linkList: ['嘉兴助听器', '安徽助听器', '上海助听器', '抚州助听器', '苏州助听器',],
      iconList: [
        {
          pic: require('@/assets/icon@2x.png')
        },
        {
          pic: require('@/assets/icon2@2x.png')
        },
        {
          pic: require('@/assets/icon3@2x.png')
        },
        {
          pic: require('@/assets/icon4@2x.png')
        },
        {
          pic: require('@/assets/icon5@2x.png')
        },
        // {
        //   pic: require('@/assets/icon6@2x.png')
        // },
        {
          pic: require('@/assets/icon7@2x.png')
        },
        {
          pic: require('@/assets/icon8@2x.png')
        },
        {
          pic: require('@/assets/icon9@2x.png')
        },
      ],
      aboutList: [
        {
          title: '惠耳简介',
          pic: require('@/assets/about@2x.png'),
          url: '/HuierProfile',
        },
        {
          title: '企业文化',
          pic: require('@/assets/culture@2x.png'),
          url: '/HuierCulture',
        },
        {
          title: '企业荣誉',
          pic: require('@/assets/trophy@2x.png'),
          url: '/enterpriseHonor',
        },
        {
          title: '惠耳风采',
          pic: require('@/assets/windmill@2x.png'),
          url: '/HuierElegantDemeanor',
        },
        {
          title: '发展历程',
          pic: require('@/assets/develop@2x.png'),
          url: '/enterpriseHonor',
        },
      ],
      cityNavList: [
        {
          name: '全国',
          icon: require('@/assets/iconPark-world Copy@2x.png'),
          iconActive: require('@/assets/iconPark-world Copy@2x.png'),
          cityName: '',
        },
        {
          name: '',
          icon: require('@/assets/iconPark-local Copy 2@2x.png'),
          iconActive: require('@/assets/iconPark-local Copy 2@2x.png'),
          cityName: '',
        },
      ],
      shopDataTotal: 0,
      shopDataList: [],
      shopQuery: {
        limit: 5,
        page: 1,
        address: '',
      },
      provinceList: [],
      showBack: false,
      isMunicipality: false,
      showPagination: true,
      page: 1,
      playerOptions: {
        autoplay: false, //如果true,浏览器准备好时开始回放。实现自动播放，autoplay、muted都设置为true
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: '27:8',
        // aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          // {
          //   type: "video/mp4", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          //   src: "", //url地址
          // },
        ],
        width: document.documentElement.clientWidth, //播放器宽度
        // height:document.documentElement.clientHeight,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
      autoplay: true,
      interval: 3000,
      initialIndex: 0, //轮播默认滑动到第一个
    }
  },
  methods: {
    getExpertsData() {
      let query = {
        limit: 10,
        sort: "position",
        order: "asc"
      }
      getExperts(query).then(res => {
        console.log("res", res)
        let experts = res.data.data.list
        for (let index = 0; index < experts.length; index += 5) {
          let arr = []
          arr.push(experts[index])
          if (index + 1 < experts.length) { arr.push(experts[index + 1]) }
          if (index + 2 < experts.length) { arr.push(experts[index + 2]) }
          if (index + 3 < experts.length) { arr.push(experts[index + 3]) }
          if (index + 4 < experts.length) { arr.push(experts[index + 4]) }
          this.expertsList.push(arr)
        }
        console.log("expertsList" , this.expertsList)
        this.options = []
        experts.forEach(item => {
          let label = item.name + item.job
          let obj = {
            value: item.id,
            label: label
          }
          this.options.push(obj)
        })
      })
    },
    getIndexData () {
      listIndex().then(res => {
        this.bannerList = res.data.data.banner
        this.bannerList.forEach((item, index) => {
          const formatIndex = item.url.lastIndexOf('.')
          const format = item.url.substr(formatIndex + 1, item.url.length)
          if (format === 'png' || format === 'jpg' || format === 'gif') {
            item.isVideo = false
          } else if (format === 'mp4' || format === 'avi') {
            item.isVideo = true
            // this.playerOptions.sources[0].src = item.url
            this.playerOptions.sources.push({ src: item.url, type: 'video/mp4' })
          }
        })
        // this.expertsList=res.data.data.expertsList
        // this.newsList=res.data.data.newsList
        // console.log(this.ordersList);

        //提交了订单
        let orders = res.data.data.ordersList
        // let experts = res.data.data.expertsList
        let shops = res.data.data.shopsList
        for (let index = 0; index < orders.length; index += 2) {
          let arr = []
          orders[index].addTime = orders[index].addTime.substr(0, 10)
          orders[index + 1].addTime = orders[index + 1].addTime.substr(0, 10)
          arr.push(orders[index])
          arr.push(orders[index + 1])
          this.ordersList.push(arr)
        }
        // release版本的评论
        // let comments = res.data.data.commentList
        // for (let index = 0; index < comments.length; index += 2) {
        //   let arr = []
        //   comments[index].updateTime = comments[index].updateTime.substr(0, 10)
        //   arr.push(comments[index])
        //   if (index + 1 < comments.length) {
        //     comments[index + 1].updateTime = comments[index + 1].updateTime.substr(0, 10)
        //     arr.push(comments[index + 1])
        //   }
        //   this.commentList.push(arr)
        // }

        // for (let index = 0; index < experts.length; index += 5) {
        //   let arr = []
        //   arr.push(experts[index])
        //   if (index + 1 < experts.length) { arr.push(experts[index + 1]) }
        //   if (index + 2 < experts.length) { arr.push(experts[index + 2]) }
        //   if (index + 3 < experts.length) { arr.push(experts[index + 3]) }
        //   if (index + 4 < experts.length) { arr.push(experts[index + 4]) }
        //   this.expertsList.push(arr)
        // }
        for (let index = 0; index < shops.length; index += 4) {
          let arr = []
          arr.push(shops[index])
          if (index + 1 < shops.length) { arr.push(shops[index + 1]) }
          if (index + 2 < shops.length) { arr.push(shops[index + 2]) }
          if (index + 3 < shops.length) { arr.push(shops[index + 3]) }
          this.shopsList.push(arr)
        }
        for (let index = 0; index < shops.length; index += 3) {
          let arr = []
          arr.push(shops[index])
          if (index + 1 < shops.length) { arr.push(shops[index + 1]) }
          if (index + 2 < shops.length) { arr.push(shops[index + 2]) }
          this.shopList.push(arr)
        }
        console.log(this.shopList);
      }).catch((error) => {
        console.log(error);
      })
    },
    getNewsData() {
      let query = {
        categoryId: this.categoryId
      }
      listNews(query).then(res => {
        // this.expertsList=res.data.data.expertsList
        // this.newsList=res.data.data.list

        let news = res.data.data.list
        console.log('news', news);
        for (let index = 0; index < news.length; index += 2) {
          let arr = []
          news[index].addTime = news[index].addTime.substr(0, 10)
          arr.push(news[index])
          // arr.push(news[index+1])
          // arr.push(news[index+2])
          if (index + 1 < news.length) {
            news[index + 1].addTime = news[index + 1].addTime.substr(0, 10)
            arr.push(news[index + 1])
          }
          // if (index + 2 < news.length) {
          //   news[index + 2].addTime = news[index + 2].addTime.substr(0, 10)
          //   arr.push(news[index + 2])
          // }
          this.newsList.push(arr)
        }
        console.log(this.newsList);

      }).catch((error) => {
        console.log(error);
      })
    },
    submit() {
      console.log('submit');
      let query = { ...this.consultingData }
      submitConsultingOnline(query).then(res => {
        console.log(res);
        this.reset()
        this.$notify.success({
          title: '成功',
          message: '信息提交成功'
        })
      }).catch((error) => {
        console.log(error);
      })
    },
    reset() {
      this.consultingData = {
        address: '',
        name: '',
        note: '',
        gender: '',
        tel: '',
      }
      this.showConsultingOnline = false
    },
    consult(item) {
      console.log(item);
      this.showConsult = true
      this.consultingExpertData.expert = item.id
    },
    submitExpert() {
      this.$refs['consultingForm'].validate((valid) => {
        if (valid) {
          let consultingTime = dayjs(this.consultingExpertData.consultTime).format('YYYY-MM-DD')
          let isDisease = this.consultingExpertData.isDisease == '是' ? true : this.consultingExpertData.isDisease == '否' ? false : ''
          let isGenetic = this.consultingExpertData.isGenetic == '是' ? true : this.consultingExpertData.isGenetic == '否' ? false : ''
          let isUsed = this.consultingExpertData.isUsed == '是' ? true : this.consultingExpertData.isUsed == '否' ? false : ''
          let hearinglossTime = this.consultingExpertData.hearinglossTime == '' ? '待选择' : this.consultingExpertData.hearinglossTime
          let data = {
            address: this.consultingExpertData.address,
            name: this.consultingExpertData.name,
            note: this.consultingExpertData.note,
            tel: this.consultingExpertData.tel,
            consultTime: consultingTime,
            expertId: this.consultingExpertData.expert,
            hearinglossTime: hearinglossTime,
            isDisease: isDisease,
            isGenetic: isGenetic,
            isUsed: isUsed,
          }
          console.log(data);
          submitExpertConsulting(data).then(res => {
            console.log(res);
            this.resetExpert()
            this.showConsult = false
            this.showResult = true
          }).catch((error) => {
            console.log(error);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetExpert() {
      this.consultingExpertData = {
        address: '',
        name: '',
        note: '',
        tel: '',
        consultTime: '',
        expert: '',
        hearinglossTime: '',
        isDisease: '',
        isGenetic: '',
        isUsed: '',
      }
      this.showConsult = false
    },
    goShopDetail(shop) {
      console.log(shop);
      this.$router.push({ path: '/ShopDetail', query: { about: shop.about, address: shop.address, name: shop.name, tel: shop.tel, url: shop.url, latitude: shop.latitude, longitude: shop.longitude } })
    },
    goServiceNet() {
      this.$router.push({ path: '/ServiceNet' })
    },
    goNews() {
      this.$router.push({ path: '/News' })
    },
    goGoodsDetail(item) {
      if (item.link.length > 0) {
        this.$router.push({ path: '/goods/details', query: { productID: item.link } })
      }
    },
    getNewGoods() {
      this.goodsQuery.isNew = 'true',
        this.goodsQuery.keyword = ''
      sortList(this.goodsQuery).then(res => {
        console.log(this.goodsQuery);
        console.log('goods', res);
        let goods = res.data.data.list
        this.list = res.data.data.list
        console.log('list', this.list)
        this.allGoods = res.data.data.list
        this.total = res.data.data.total
        // this.list.push(goods)
        console.log(this.list);
      })
    },
    getGoods() {
      this.goodsQuery.keyword = ''
      sortList(this.goodsQuery).then(res => {
        console.log(this.goodsQuery);
        console.log('goods', res);
        let goods = res.data.data.list
        this.list = res.data.data.list
        console.log('list', this.list)
        this.allGoods = res.data.data.list
        this.total = res.data.data.total
        // this.list.push(goods)
        console.log(this.list);
      })
    },
    currentChange(currentPage) {
      this.goodsQuery.page = currentPage
      this.getGoods()
    },
    goAbout(url) {
      this.$router.push({ path: url })
    },
    getShopsList() {
      getShops(this.shopQuery).then(res => {
        console.log(res);
        this.shopDataList = res.data.data.list
        this.shopDataTotal = res.data.data.total
      })
    },
    shopDataChange(currentPage) {
      this.shopQuery.page = currentPage
      this.getShopsList()
    },
    init() {
      let provinceList = []
      let cityList = []
      let city = this.areaList.city_list
      let province = this.areaList.province_list
      for (let i in province) {
        let provinceName
        if (i == 150000) {
          provinceName = '内蒙古'
        } else if (i == 450000) {
          provinceName = '广西'
        } else if (i == 540000) {
          provinceName = '西藏'
        } else if (i == 640000) {
          provinceName = '宁夏'
        } else if (i == 650000) {
          provinceName = '新疆'
        } else {
          provinceName = province[i].slice(0, province[i].length - 1)
        }
        let obj = {
          provinceId: i,
          provinceName: provinceName
        }
        provinceList.push(obj)
      }
      // this.provinceList=provinceList
      let showProvince = provinceList
      let rowArr = []
      for (let index = 0; index < showProvince.length; index += 5) {
        let arr = []
        // showProvince[index].addTime=showProvince[index].addTime.substr(0,10)
        arr.push(showProvince[index])
        // arr.push(news[index+1])
        // arr.push(news[index+2])
        if (index + 1 < showProvince.length) {
          arr.push(showProvince[index + 1])
        }
        if (index + 2 < showProvince.length) {
          arr.push(showProvince[index + 2])
        }
        if (index + 3 < showProvince.length) {
          arr.push(showProvince[index + 3])
        }
        if (index + 4 < showProvince.length) {
          arr.push(showProvince[index + 4])
        }
        // if (index + 5 < showProvince.length) {
        //   arr.push(showProvince[index + 5])
        // }
        // if (index+6<showProvince.length) {
        //   arr.push(showProvince[index+6])
        // }
        rowArr.push(arr)
      }
      for (let index = 0; index < rowArr.length; index += 3) {
        let arr = []
        arr.push(rowArr[index])
        if (index + 1 < rowArr.length) {
          arr.push(rowArr[index + 1])
        }
        if (index + 2 < rowArr.length) {
          arr.push(rowArr[index + 2])
        }
        this.provinceList.push(arr)
      }
      for (let i in city) {
        let obj = {
          cityId: i,
          cityName: city[i]
        }
        cityList.push(obj)
      }
      this.cityList = cityList
    },
    changeToCity(item) {
      if (item.provinceId == 110000 || item.provinceId == 120000 || item.provinceId == 310000 || item.provinceId == 500000) {
        this.shopQuery.address = item.provinceName
        this.page = 1
        // this.showPagination = false
        this.getShopsList()
        // this.nextTick(() => {
        // 	this.showPagination = true
        // })
      } else {
        this.provinceList = []
        console.log(item);
        this.showBack = true
        let provinceItemId = item.provinceId
        let cityList = []
        let newCityList = this.cityList.filter(function (element, index, array) {
          return element.cityId.slice(0, 2) == provinceItemId.slice(0, 2)
        })
        console.log('222', newCityList);
        let rowArr = []
        for (let index = 0; index < newCityList.length; index += 5) {
          let arr = []
          arr.push(newCityList[index])
          if (index + 1 < newCityList.length) {
            arr.push(newCityList[index + 1])
          }
          if (index + 2 < newCityList.length) {
            arr.push(newCityList[index + 2])
          }
          if (index + 3 < newCityList.length) {
            arr.push(newCityList[index + 3])
          }
          if (index + 4 < newCityList.length) {
            arr.push(newCityList[index + 4])
          }
          // if (index + 5 < newCityList.length) {
          //   arr.push(newCityList[index + 5])
          // }
          // if (index+6<newCityList.length) {
          //   arr.push(newCityList[index+6])
          // }
          rowArr.push(arr)
        }
        for (let index = 0; index < rowArr.length; index += 3) {
          let arr = []
          arr.push(rowArr[index])
          if (index + 1 < rowArr.length) {
            arr.push(rowArr[index + 1])
          }
          if (index + 2 < rowArr.length) {
            arr.push(rowArr[index + 2])
          }
          this.provinceList.push(arr)
        }
        console.log('111', this.provinceList);
      }
    },
    goBack() {
      this.showBack = false
      this.provinceList = []
      this.init()
    },
    filterByCity(item) {
      console.log(item);
      this.goBack()
      this.shopQuery.address = item.cityName
      this.shopQuery.page = 1
      this.page = 1
      this.getShopsList()
    },
    searchGoods() {
      this.goodsQuery.isNew = ''
      this.goodsQuery.keyword = this.search
      sortList(this.goodsQuery).then(res => {
        this.list = res.data.data.list
        this.total = res.data.data.total
      }).catch((error) => {
        console.log(error);
      })
    },
    filterByName(name) {
      this.goodsQuery.isNew = ''
      this.goodsQuery.keyword = name
      sortList(this.goodsQuery).then(res => {
        this.list = res.data.data.list
        this.total = res.data.data.total
      }).catch((error) => {
        console.log(error);
      })
    },
    filterByCondition(condition) {
      this.goodsQuery.sort = condition
      sortList(this.goodsQuery).then(res => {
        this.list = res.data.data.list
        this.total = res.data.data.total
        console.log(this.total);
      }).catch((error) => {
        console.log(error);
      })
    },
    goDetail(item) {
      // console.log('1245',item);
      this.$router.push({ path: '/NewsDetail', query: { item: encodeURIComponent(JSON.stringify(item)) } })
    },
    getLocationBaiduIp() {/*获取用户当前位置（ip定位）*/
      myBMap.init().then(BMap => {
        let myCity = new BMap.LocalCity();
        myCity.get(
          result => {
            let geoc = new BMap.Geocoder();
            geoc.getLocation(result.center, res => {
              // 经纬度
              console.log(result.center);
              // 位置信息
              console.log(res.addressComponents.city);
              this.cityNavList[1].name = res.addressComponents.city
              this.cityNavList[1].cityName = res.addressComponents.city
            });
          },
          { enableHighAccuracy: true }
        );
      });

    },
    onPlayerLoadeddata(player) {
      // 获取资源总时长
      this.duration = player.cache_.duration;
    },
    //监听媒体是否已到达结尾，播放完
    onPlayerEnded(player) {
      this.autoplay = true;
      this.interval = 3000
    },
    // 监听轮播图改变
    onChange (index) {
      // 如果是视频,就等播放完再进行下一个
      if (this.bannerList[index].isVideo === true && this.playerOptions.sources.length > 1) {
        for (let i = 0; i < this.playerOptions.sources.length; i++) {
          this.$refs.videoPlayer[i].player.src(this.playerOptions["sources"][i]["src"]) // 重置进度条,再次轮播时让视频重新播放
          this.interval = this.duration * 1000
          this.autoplay = false
        }
      } else if (this.bannerList[index].isVideo === true && this.playerOptions.sources.length === 1) {
        this.$refs.videoPlayer[0].player.src(this.playerOptions["sources"][0]["src"]) // 重置进度条,再次轮播时让视频重新播放
        this.interval = this.duration * 1000
        this.autoplay = false
      }
    },
    //     initVideo(){
    //       var playerVideo = videoJS("video", this.playerOptions, function onPlayerReady() {
    //       videoJS.log('Your player is ready!');

    //       this.on("loadstart",function(){
    //           console.log("开始请求数据 ");
    //       })
    //       this.on("progress",function(){
    //           console.log("正在请求数据 ");
    //       })
    //       this.on("loadedmetadata",function(){
    //           console.log("获取资源长度完成 ")
    //       })
    //       this.on("canplaythrough",function(){
    //           console.log("视频源数据加载完成")
    //       })
    //       this.on("waiting", function(){
    //           console.log("等待数据")
    //       });
    //       this.on("play", function(){
    //           console.log("视频开始播放")
    //       });
    //       this.on("playing", function(){
    //           console.log("视频播放中")
    //       });
    //       this.on("pause", function(){
    //           console.log("视频暂停播放")
    //       });
    //       this.on("ended", function(){
    //           console.log("视频播放结束");
    //       });
    //       this.on("error", function(){
    //           console.log("加载错误")
    //       });
    //       this.on("seeking",function(){
    //           console.log("视频跳转中");
    //       })
    //       this.on("seeked",function(){
    //           console.log("视频跳转结束");
    //       })
    //       this.on("ratechange", function(){
    //           console.log("播放速率改变")
    //       });
    //       this.on("timeupdate",function(){
    //           console.log("播放时长改变");
    //       })
    //       this.on("volumechange",function(){
    //           console.log("音量改变");
    //       })
    //       this.on("stalled",function(){
    //           console.log("网速异常");
    //       })
    // });
    //     }
  },
  mounted() {
    this.getIndexData()
    this.getNewsData()
    this.getGoods()
    this.getShopsList()
    this.init()
    this.getLocationBaiduIp()
    this.getExpertsData()
    // this.$nextTick(() => {
    //   this.initVideo()
    // });
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 70%;
  margin: 0 auto;
  padding-left: 0;
}

.swiperWrap {
  width: 100%;
  height: 15.125rem
    /* 242/16 */
  ;
  margin-bottom: 1.25rem
    /* 20/16 */
  ;
}

.banner {
  width: 100%;
  height: 15.125rem
    /* 242/16 */
  ;
}

.img {
  width: 100%;
  height: 100%;
  padding: 0 .0625rem
    /* 1/16 */
  ;
  box-sizing: border-box;
}

.bannerVideo {
  width: 100%;
  height: 100%;
  padding: 0 .0625rem
    /* 1/16 */
  ;
  box-sizing: border-box;
}

.homePage {
  display: flex;
  justify-content: left;
  margin-bottom: 3.125rem
    /* 50/16 */
  ;
  margin-top: .875rem
    /* 14/16 */
  ;
}

.pageLeft {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-right: 2.625rem
    /* 42/16 */
  ;
  width: 70%;
}

.leftTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem
    /* 16/16 */
  ;
}

.icons {
  margin-right: .875rem
    /* 14/16 */
  ;
  width: 70%;
}

/deep/.el-card__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
}

.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-right: 1px solid rgba(224, 224, 224, 0.86);
  height: 3.875rem
    /* 62/16 */
  ;
}

.iconImg img {
  width: 1.5625rem
    /* 25/16 */
  ;
}

.iconTxt {
  font-size: .75rem
    /* 12/16 */
  ;
  color: rgba(17, 17, 17, 1);
}

.consultingOnline {
  width: 28%;
  background-color: #4d8047;
  padding: 1.125rem
    /* 18/16 */
    0;
  border-radius: .5rem
    /* 8/16 */
  ;
  cursor: pointer;
}

.consulting {
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-color: #4d8047;*/
  height: 100%;

}

.consultingIcon {
  width: 1.5625rem
    /* 25/16 */
  ;
  height: 1.5625rem
    /* 25/16 */
  ;
}

.consultingIcon img {
  width: 1.5625rem
    /* 25/16 */
  ;
  height: 1.5625rem
    /* 25/16 */
  ;
}

.consultingTxt {
  font-size: 1.125rem
    /* 18/16 */
  ;
  color: rgba(249, 249, 249, 1);
}

.consultingArrow {
  width: 1.5625rem
    /* 25/16 */
  ;
  height: 1.5625rem
    /* 25/16 */
  ;
}

.consultingArrow img {
  width: 1.5625rem
    /* 25/16 */
  ;
  height: 1.5625rem
    /* 25/16 */
  ;
}

.leftCenter {
  width: 100%;
  height: 6.25rem
    /* 100/16 */
  ;

}

.clearfix {
  color: rgba(250, 126, 34, 1);
  font-size: 1.125rem
    /* 18/16 */
  ;
  font-weight: bold;
}

/deep/.el-card__header {
  padding: .625rem
    /* 10/16 */
  ;
}

.orderSwiper {
  width: 100%;
  height: 4rem
    /* 64/16 */
  ;
  margin-top: .625rem
    /* 10/16 */
  ;
}

.order-card {
  height: fit-content;
}

.swiper-slide {
  height: 1.25rem
    /* 20/16 */
    !important;
  margin-bottom: 3.125rem
    /* 50/16 */
    !important;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .625rem
    /* 10/16 */
  ;
  width: 100%;
  height: 1rem;
  margin-bottom: .9375rem
    /* 15/16 */
  ;
  box-sizing: border-box;
}

.nickName {
  width: 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(17, 17, 17, 1);
  font-size: 1rem;
  text-align: left;
  font-family: SourceHanSansSC-regular;
}

.order {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(17, 17, 17, 1);
  font-size: 1rem;
  text-align: left;
  font-family: SourceHanSansSC-regular;
}

.date {
  width: 30%;
  font-size: .75rem
    /* 12/16 */
  ;
  color: rgba(17, 17, 17, 1);
  font-size: 1rem;
  text-align: right;
  font-family: SourceHanSansSC-regular;
}

.leftBottom {
  width: 100%;
  margin-top: 1.875rem
    /* 30/16 */
  ;
}

.expertCard {
  height: 13.8125rem
    /* 221/16 */
  ;
}

.expertSwiper {
  width: 100%;
  height: 11.25rem
    /* 180/16 */
  ;
  margin-top: .625rem
    /* 10/16 */
  ;
}

.experts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.expert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  padding: 0 .625rem
    /* 10/16 */
  ;
  box-sizing: border-box;
  margin: .625rem
    /* 10/16 */
    0;
}

.expertItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem
    /* 112/16 */
  ;
  height: 9.5rem
    /* 152/16 */
  ;
  border: 1px solid rgba(224, 224, 224, 0.86);
  border-radius: .25rem
    /* 4/16 */
  ;
  margin: .3125rem
    /* 5/16 */
  ;
  box-sizing: border-box;
}

.expertImg {
  width: 5.375rem
    /* 86/16 */
  ;
  height: 6rem
    /* 96/16 */
  ;
  margin: .25rem
    /* 4/16 */
    0;
}

.expertImg img {
  width: 100%;
  height: 100%;
  border-radius: .5rem
    /* 8/16 */
  ;
}

.expertName {
  margin-bottom: .125rem
    /* 2/16 */
  ;
  font-size: .625rem
    /* 10/16 */
  ;
  font-weight: bolder;
}

.expertConsult {
  margin-bottom: .625rem
    /* 10/16 */
  ;
  font-size: .5rem
    /* 8/16 */
  ;
}

.button {
  width: 2.75rem
    /* 44/16 */
  ;
  height: 1.125rem
    /* 18/16 */
  ;
  line-height: 1.25rem
    /* 20/16 */
  ;
  border-radius: .5rem
    /* 8/16 */
  ;
  background-color: rgba(255, 156, 1, 1);
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 8px;
  cursor: pointer;
}

.pageRight {
  width: 39%;
}

.right {
  width: 100%;
  height: fit-content;
  margin-bottom: 1.625rem
    /* 26/16 */
  ;
}

.newsCard {
  height: 15.8125rem
    /* 253/16 */
  ;
}

.headerNews {
  padding: .625rem
    /* 10/16 */
  ;
}

.newsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.newsMore {
  color: rgba(158, 152, 148, 1);
  font-size: .875rem
    /* 14/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.moreNews {
  padding-right: .625rem
    /* 10/16 */
  ;
}

.part {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 .0625rem
    /* 1/16 */
  ;
}

.part1 {
  width: 25%;
  height: .3125rem
    /* 5/16 */
  ;
  background-color: rgba(234, 0, 0, 0.88);

}

.part2 {
  width: 25%;
  height: .3125rem
    /* 5/16 */
  ;
  background-color: rgba(187, 187, 187, 1);

}

.part3 {
  width: 25%;
  height: .3125rem
    /* 5/16 */
  ;
  background-color: rgba(255, 197, 115, 1);

}

.part4 {
  width: 25%;
  height: .3125rem
    /* 5/16 */
  ;
  background-color: rgba(249, 223, 188, 1);

}

.newsMore img {
  width: 1.125rem
    /* 18/16 */
  ;
  height: 1.125rem
    /* 18/16 */
  ;
}

.newsSwiper {
  width: 100%;
  height: 12.6875rem
    /* 203/16 */
  ;
}

.news {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.newsItem {
  width: 100%;
  height: 50%;
}

.newsIn {
  align-items: center;
  width: 100%;
  padding: .625rem
    /* 10/16 */
    0;
}

.newsLeft {
  width: 25%;
  text-align: center;
  height: 2.875rem
    /* 46/16 */
  ;

}

.newsLeft img {
  width: 4.75rem
    /* 76/16 */
  ;
  height: 2.875rem
    /* 46/16 */
  ;

}

.newsRight {
  justify-content: left;
  align-items: left;
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}

.centerTop {
  width: 90%;

  align-items: center;
  flex-direction: column;
}

.newsTitle {
  font-size: 1rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-family: SourceHanSansSC-regular;
  color: rgba(17, 17, 17, 1);
}

.newsDate {
  color: rgba(17, 17, 17, 1);
  font-size: 1rem;
  font-family: SourceHanSansSC-regular;
}

.newsDate img {
  width: .75rem
    /* 10/16 */
  ;
  height: .75rem
    /* 10/16 */
  ;
}

.newsContent {
  color: rgba(142, 142, 142, 0.88);
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2;
  /** 显示的行数 **/
  width: 100%;
  font-family: SourceHanSansSC-light;
}

.rightBottom {
  width: 100%;
  height: fit-content;
  margin-top: 3.75rem
    /* 60/16 */
  ;
}

.showAddress {
  width: 100%;
}

.address-card {
  width: 100%;
  height: 9.375rem
    /* 150/16 */
  ;
}

.addressSwiper {
  width: 100%;
  height: 8rem
    /* 128/16 */
  ;
}

.addressLeft {
  width: 40%;
  text-align: center;
}

.addressLeft img {
  width: 9rem
    /* 144/16 */
  ;
  height: 5.25rem
    /* 84/16 */
  ;
}

.addressRight {
  width: 100%;
  padding-left: 1.875rem
    /* 30/16 */
  ;
}

.showProvinceList {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  padding: 0 .625rem
    /* 10/16 */
  ;
  box-sizing: border-box;
}

.addressItem {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: .3125rem
    /* 5/16 */
    0;
}

.provinceItem {
  width: 20%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.shopName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgba(17, 17, 17, 0.86);
  font-size: 1rem;
  width: 95%;
  cursor: pointer;
}

.shopDetail {
  width: 30%;
  color: rgba(77, 128, 71, 1);
  font-size: .5rem
    /* 8/16 */
  ;
  text-align: right;
  padding: 0 .625rem
    /* 10/16 */
  ;
  cursor: pointer;
}

.aboutUs {
  margin-top: 3.125rem
    /* 50/16 */
  ;
  margin-bottom: 3.125rem
    /* 50/16 */
  ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutLeft {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  width: 60%;
  padding-right: 2.25rem
    /* 36/16 */
  ;
}

.aboutTitle {
  display: flex;
  justify-content: left;
  align-items: flex-end;
  margin-bottom: 4.0625rem
    /* 65/16 */
  ;
}

.titleCN {
  color: rgba(77, 128, 71, 1);
  font-size: 1.5rem
    /* 24/16 */
  ;
}

.titleUS {
  color: rgba(158, 152, 148, 1);
  font-size: .875rem
    /* 14/16 */
  ;
}

.aboutContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.6875rem
    /* 43/16 */
  ;
}

.content {
  color: rgba(17, 17, 17, 1);
  font-size: .875rem
    /* 14/16 */
  ;
  padding: 0 .625rem
    /* 10/16 */
  ;
  font-family: SourceHanSansSC-regular;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 3;
  /** 显示的行数 **/
}

.moreContent {
  display: flex;
  justify-content: right;
  align-items: center;
  color: rgba(158, 152, 148, 1);
  font-size: .875rem
    /* 14/16 */
  ;
  padding-right: 2.25rem
    /* 36/16 */
  ;
  width: 100%;
}

.moreContent img {
  width: 1rem
    /* 16/16 */
  ;
  height: 1rem
    /* 16/16 */
  ;
}

.aboutRight {
  width: 40%;
  text-align: center;
}

.aboutRight img {
  width: 100%;
  height: fit-content;
}

.aboutIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aboutBox {
  width: 18%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .25rem
    /* 4/16 */
  ;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(187, 187, 187, 1);
  padding: .625rem
    /* 10/16 */
    0;
  box-sizing: border-box;
  cursor: pointer;
}

.aboutBox img {
  width: 1.625rem
    /* 26/16 */
  ;
  height: 1.625rem
    /* 26/16 */
  ;
}

.detailTitle {
  color: rgba(16, 16, 16, 1);
  font-size: .75rem
    /* 12/16 */
  ;
  margin-left: .3125rem
    /* 5/16 */
  ;
}

.chainStore {
  margin-bottom: 3.125rem
    /* 50/16 */
  ;
}

.shopTop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.shopTitle {
  display: flex;
  justify-content: left;
  align-items: flex-end;
}

.moreShop {
  position: absolute;
  right: 0;
  color: rgba(158, 152, 148, 1);
  font-size: .875rem
    /* 14/16 */
  ;
  padding-right: 2.25rem
    /* 36/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.moreShop img {
  width: 1rem
    /* 16/16 */
  ;
  height: 1rem
    /* 16/16 */
  ;
}

.shops {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shopItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .75rem
    /* 12/16 */
    .0625rem
    /* 1/16 */
  ;
}

.shop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 18.1875rem
    /* 291/16 */
  ;
  height: 20.5625rem
    /* 329/16 */
  ;
  padding: 0 .625rem
    /* 10/16 */
  ;
  box-sizing: border-box;
  margin: .625rem
    /* 10/16 */
    0;
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: .5rem
    /* 8/16 */
  ;
}

.shopImg {
  margin: 1.25rem
    /* 20/16 */
    0;
  cursor: pointer;
  width: 16.125rem
    /* 258/16 */
  ;
  height: 15rem
    /* 240/16 */
  ;
}

.shopImg img {
  width: 100%;
  height: 100%;
}

.shopConsult {
  margin-bottom: 1.25rem
    /* 20/16 */
  ;
}

/deep/.el-card.is-always-shadow {
  background-color: rgba(249, 249, 249, 1);
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapLeft {
  width: 50%;
}

.wrapLeftResult {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.leftImg {
  width: 3.125rem
    /* 50/16 */
  ;
  height: 3.125rem
    /* 50/16 */
  ;
  margin-bottom: 1.5625rem
    /* 25/16 */
  ;
}

.leftImg img {
  width: 100%;
  height: 100%;
}

.leftWord {
  color: rgba(16, 16, 16, 1);
  font-size: 1.125rem
    /* 18/16 */
  ;
}

.wrapRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.publicImg {
  width: 7.625rem
    /* 122/16 */
  ;
  height: 8.625rem
    /* 138/16 */
  ;
  margin-bottom: 1rem
    /* 16/16 */
  ;
}

.publicImg img {
  width: 100%;
  height: 100%;
}

.publicContent {
  color: rgba(16, 16, 16, 1);
  font-size: 1rem
    /* 16/16 */
  ;
}

a {
  text-decoration: none
}

.active {
  cursor: pointer;
}

.friendlyLink {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.linkTitle {
  font-size: 1.25rem
    /* 20/16 */
  ;
  width: 15%;
}

.linkTitle img {
  width: .9375rem
    /* 15/16 */
  ;
  height: .9375rem
    /* 15/16 */
  ;
}

.linkList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  widows: 85%;
}

.link {
  width: 20%;
  height: 3.25rem
    /* 52/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem
    /* 16/16 */
  ;
  font-size: .875rem
    /* 14/16 */
  ;
  cursor: pointer;
}

.link img {
  width: 3.25rem
    /* 52/16 */
  ;
  height: 3.25rem
    /* 52/16 */
  ;
}

.showShop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shopNav {
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: .25rem
    /* 4/16 */
  ;
  width: 100%;
  margin-bottom: .625rem
    /* 10/16 */
  ;
}

.navIcon {
  width: 4rem
    /* 64/16 */
  ;
  height: 3.75rem
    /* 60/16 */
  ;
  background-color: rgba(77, 128, 71, 1);
  border-radius: .25rem
    /* 4/16 */
    0px 0px .25rem
    /* 4/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navIcon img {
  width: 2.625rem
    /* 42/16 */
  ;
  height: 2.625rem
    /* 42/16 */
  ;
}

.navDetail {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 3.75rem
    /* 60/16 */
  ;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 0 .25rem
    /* 4/16 */
    .25rem
    /* 4/16 */
    0;
}

.cityItem {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 1.25rem
    /* 20/16 */
  ;
  cursor: pointer;
}

.cityIcon {
  width: .875rem
    /* 14/16 */
  ;
  height: .875rem
    /* 14/16 */
  ;
}

.cityIcon img {
  width: 100%;
  height: 100%;
}

.cityName {
  font-size: 1rem
    /* 16/16 */
  ;
  color: rgba(16, 16, 16, 1);
  margin-left: .3125rem
    /* 5/16 */
  ;
}

.activeCity {
  color: rgba(255, 156, 1, 1);
}

.shopData {
  width: 100%;
  margin-bottom: 3.125rem
    /* 50/16 */
  ;
}

.shopDataList {
  width: 100%;
}

.showGoods {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 3.125rem
    /* 50/16 */
  ;
}

.goodsTop {
  width: 100%;
  height: 5.375rem
    /* 86/16 */
  ;
  background-color: rgba(77, 128, 71, 1);
  display: flex;
  justify-content: left;
  align-items: center;
}

.showTitle {
  width: 10.3125rem
    /* 165/16 */
  ;
  background: linear-gradient(180deg, rgba(0, 71, 24, 0.35) 0%, rgba(0, 71, 24, 0.05) 100%);
  height: 5.375rem
    /* 86/16 */
  ;
  line-height: 5.375rem
    /* 86/16 */
  ;
  text-align: center;
  font-size: 1.125rem
    /* 18/16 */
  ;
  color: rgba(240, 240, 240, 1);
}

.goodsRight {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  color: rgba(240, 240, 240, 1);
  font-size: 1.125rem
    /* 18/16 */
  ;
}

.rightBox {
  width: 20%;
  text-align: center;
  cursor: pointer;
}

/deep/.el-input__inner {
  height: 2rem
    /* 32/16 */
  ;
  line-height: 2rem
    /* 32/16 */
  ;
}

/deep/.el-input__icon {
  height: 2rem
    /* 32/16 */
  ;
  line-height: 2rem
    /* 32/16 */
  ;
}

.goodsCenter {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.centerLeft {
  width: 10.3125rem
    /* 165/16 */
  ;
  height: 100%;
  background-color: rgba(77, 128, 71, 1);
  height: 34.6875rem
    /* 555/16 */
  ;
}

.classifyList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgba(255, 255, 255, 1);
  font-size: 1.125rem
    /* 18/16 */
  ;
  background-color: rgba(77, 128, 71, 1);
  width: 10.3125rem
    /* 165/16 */
  ;
}

.classify {
  margin: 1.4375rem
    /* 23/16 */
  ;
  cursor: pointer;
}

.centerRight {
  margin-top: 1.75rem
    /* 28/16 */
  ;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.showList {
  width: 80%;
}

.none-product {
  text-align: center;
}

.showCompany {
  width: 20%;
  min-width: 12rem
    /* 192/16 */
  ;
  border: .0625rem
    /* 1/16 */
    solid rgba(187, 187, 187, 1);
  border-radius: .25rem
    /* 4/16 */
  ;
  font-family: Roboto;
  font-size: 1.125rem
    /* 18/16 */
  ;
  height: 32.8125rem
    /* 525/16 */
  ;
}

.recommend {
  width: 100%;
  height: 1.875rem
    /* 30/16 */
  ;
  text-align: center;
}

.pagination {
  text-align: center;
  margin: 1.25rem
    /* 20/16 */
    auto;
}

.showRecommend {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.iconItem {
  width: 4.4375rem
    /* 71/16 */
  ;
  height: 1.9375rem
    /* 31/16 */
  ;
  border-radius: .25rem
    /* 4/16 */
  ;
  border: .0625rem
    /* 1/16 */
    solid rgba(187, 187, 187, 1);
  margin: .625rem
    /* 10/16 */
  ;
}

.iconItem img {
  width: 100%;
  height: 100%;
}

.moreImg {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.moreImg img {
  width: 1rem
    /* 16/16 */
  ;
  height: 1rem
    /* 16/16 */
  ;
  margin-right: .3125rem
    /* 5/16 */
  ;
}

/deep/.el-carousel__button {
  background-color: #4d8047;
}

.myVideo {
  width: 100%;
  height: 100%;
}

.videoBox {
  width: 100%;
  height: 100%;
}

.videoBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #909399;
  background: #1d283b;

  /deep/ .video-js .vjs-tech {
    object-fit: fill;
  }

  /deep/.vjs-custom-skin>.video-js {
    width: 100%;
    height: 100%;
  }

  .videoBox {
    width: 100%;
    height: 100%;
  }
}
</style>
