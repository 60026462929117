<template>
    <div>
        <!-- 咨询顾问 -->
        <el-dialog width="60%" :visible.sync="showLeaveMessage" title="咨询顾问" :before-close="close"
            v-if="showLeaveMessage">
            <div class="wrap">
                <div class="wrapLeft">
                    <el-form ref="form" :model="consultingData" label-width="100px" :rules="consultRules">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="consultingData.name"></el-input>
                        </el-form-item>
                        <el-form-item label="E-mail" prop="email">
                            <el-input v-model="consultingData.email"></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="gender">
                            <el-input v-model="consultingData.gender"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="tel">
                            <el-input v-model="consultingData.tel"></el-input>
                        </el-form-item>
                        <el-form-item label="留言主题" prop="title">
                            <el-input v-model="consultingData.title"></el-input>
                        </el-form-item>
                        <el-form-item label="留言内容" prop="detail">
                            <el-input v-model="consultingData.detail" type="textarea" autosize></el-input>
                        </el-form-item>
                        <el-form-item label="" class="prompt">
                            温馨提示：带 “<div style="color:red">*</div>” 为必填项
                        </el-form-item>
                    </el-form>
                </div>
                <div class="wrapRight">
                    <div class="publicImg">
                        <img src="@/assets/public@3x.png" alt="">
                    </div>
                    <div class="publicContent">
                        微信预约配机享受<span style="color:red">200</span>元优惠(仅限于全数字助听器)
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submit">确定</el-button>
            </div>
        </el-dialog>
        <!-- 咨询顾问提交成功 -->
        <el-dialog width="60%" :visible.sync="showResult" title="提交成功" v-if="showResult">
            <div class="wrap">
                <div class="wrapLeftResult">
                    <div class="leftImg">
                        <img src="@/assets/semiDesign-semi-icons-verify Copy@3x.png" alt="">
                    </div>
                    <div class="leftWord">提交成功，后续客服会与您联系</div>
                </div>
                <div class="wrapRight">
                    <div class="publicImg">
                        <img src="@/assets/public@3x.png" alt="">
                    </div>
                    <div class="publicContent">
                        微信预约配机享受<span style="color:red">200</span>元优惠(仅限于全数字助听器)
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showResult = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { submitMessage } from '@/api/mall';
export default {
    props: {
        showLeaveMessage: Boolean,
    },
    data() {
        return {
            //咨询顾问相关
            // showDiaLog: this.showLeaveMessage, //咨询顾问弹窗
            showResult: false, //咨询顾问提交成功弹窗
            consultingData: { //咨询顾问表单数据
                name: '', //顾客姓名
                email: '', //顾客邮箱E-mail
                gender: '', //性别
                tel: '', //联系电话
                title: '', //留言主题
                detail: '', //留言内容
            },
            consultRules: { //咨询顾问表单规则
                name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                email: [{ required: true, message: '邮箱不能为空', trigger: 'blur' }],
                gender: [{ required: true, message: '性别不能为空', trigger: 'blur' }],
                tel: [{ required: true, message: '联系手机不能为空', trigger: 'blur' }],
                title: [{ required: true, message: '留言主题不能为空', trigger: 'blur' }],
                detail: [{ required: true, message: '留言内容不能为空', trigger: 'blur' }],
            },
        };
    },
    methods: {
        //咨询顾问表单提交
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    console.log('submit');
                    let query = { ...this.consultingData };
                    submitMessage(query).then(res => {
                        console.log(res);
                        this.reset();
                        this.showResult = true;
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //咨询顾问表单取消
        close() {
            this.consultingData = {
                name: '',
                email: '',
                qq: '',
                tel: '',
                title: '',
                tel: '',
            };
            this.$emit('close')
        },
    }
};
</script>



<style>
/* 咨询顾问表单 */
.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapLeft {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wrapLeftResult {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.leftImg {
    width: 3.125rem
        /* 50/16 */
    ;
    height: 3.125rem
        /* 50/16 */
    ;
    margin-bottom: 1.5625rem
        /* 25/16 */
    ;
}

.leftImg img {
    width: 100%;
    height: 100%;
}

.leftWord {
    color: rgba(16, 16, 16, 1);
    font-size: 1.125rem
        /* 18/16 */
    ;
}

.wrapRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.publicImg {
    width: 7.625rem
        /* 122/16 */
    ;
    height: 8.625rem
        /* 138/16 */
    ;
    margin-bottom: 1rem
        /* 16/16 */
    ;
}

.publicImg img {
    width: 100%;
    height: 100%;
}

.publicContent {
    color: rgba(16, 16, 16, 1);
    font-size: 1rem
        /* 16/16 */
    ;
}

.prompt .el-form-item__content {
    display: flex !important;
    flex-direction: row !important;
}
</style>