<template>
  <div id="app" :style="{ 'minWidth': minWidth }">
    <!-- <nav>
                          <router-link to="/">Home</router-link> |
                          <router-link to="/about">About</router-link>
                        </nav>
                        <router-view/> -->
    <el-container class="containerBox">
      <!-- 顶栏容器 -->
      <el-header>
        <!-- 顶部导航栏 -->
        <div class="topBar">
          <div class="nav">
            <div class="navLeft">
              <img src="./assets/logo.png" alt="">
            </div>
            <div class="navRight">    
              <!-- <div class="rightTop">
                <img src="./assets/tel@2x.png" alt="">
                <div class="telTxt">服务热线：<span style="color:#6D9768">400-8899-485</span></div>
              </div> -->
              <div class="rightBottom">
                <div class="box box1" v-if="nickName" > {{ nickName }} </div>
                <div class="box box1" v-else @click="wxLogin1()"> 登录 </div>
                <router-link :to="{ path: '/center/center' }">
                  <div class="box">个人中心</div>
                </router-link>

                <div class="rightTop1">
                <img src="./assets/tel@2x.png" alt="">
                <div class="telTxt">服务热线：<span style="color:#6D9768">400-8899-485</span></div>
              </div>
                <!-- <div class="box">ENGLISH</div>
                <div class="searching">
                  <el-input placeholder="Search" suffix-icon="el-icon-search"></el-input>
                </div> -->
              </div>
            </div>
          </div>
          <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleSelect"
            background-color="#4D8047 " text-color="#fff" active-text-color="#ffd04b" :router="true">
            <template v-for="(menu) in menuData">
              <el-submenu :key="menu.index" :index="menu.index" v-if="menu.children" class="navBox">
                <template slot="title">
                  <el-image style="width: 18px; height: 18px" :src="menu.pic" alt="" id="menuImg" lazy>
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                  <span slot="title">{{ menu.name }}</span>
                </template>
                <el-menu-item :key="itemIndex" :index="item.url" v-for="item, itemIndex in menu.children"
                  class="navChildrenBox">
                  <span slot="title">{{ item.name }}</span>
                </el-menu-item>
              </el-submenu>
              <el-menu-item :key="menu.index + '-only'" :index="menu.url" v-else class="navBox">
                <el-image :src="menu.pic" alt="" lazy>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <span slot="title">{{ menu.name }}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
        <div class="rightNav">
          <div class="rightNavBox" @click="openWindow(consultationOnlineURL, '在线咨询')">
            <!-- consultationOnline(consultationOnlineURL) -->
            <img src="@/assets/ze-chat Copy@2x.png" alt="">
            <div class="rightNavBoxTitle">在线咨询</div>
          </div>
          <div class="rightNavBox" @click="showLeaveMessage = true">
            <img src="@/assets/fas fa-user Copy@2x.png" alt="">
            <div class="rightNavBoxTitle">咨询顾问</div>
          </div>
          <!-- 删去“发送地址到手机” -->
          <!-- <div class="rightNavBox">
                                <img src="@/assets/md-phone_iphone Copy@2x.png" alt="">
                                <div class="rightNavBoxTitle">发送地址到手机</div>
                              </div> -->
          <div class="rightNavBox" v-on:mouseover="overShow" v-on:mouseout="outHide">
            <img src="@/assets/arcoDesign-qrcode Copy@2x.png" alt="">
            <div class="rightNavBoxTitle">微信咨询</div>
          </div>
          <div class="rightNavBox" @click="backToTop()">
            <img src="@/assets/iconPark-to-top Copy@2x.png" alt="">
            <div class="rightNavBoxTitle">返回顶部</div>
          </div>
        </div>
        <div id="publicPic" style="display:none">
          <div class="publicTitle">惠耳官方微信</div>
          <img src="@/assets/publicPic@2x.png" alt="">
        </div>
      </el-header>
      <el-main>
        <!-- <div class="container">
                              <div class="breadCrumb">
                                当前位置：<span class="currentCrumb">产品体系</span>
                              </div>
                            </div> -->

        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
      <el-footer>
        <div class="footerTop">
          <div class="topIn">
            <div class="footerInfo">
              <div class="infoLeft">
                <div class="footerMenu">
                  <div class="footerItem" v-for="item, index in footerData" :key="index">
                    <div class="itemTitle">{{ item.name }}</div>
                    <div class="itemList">
                      <div class="listItem" v-for="menu, index in item.children" :key="index" @click="router_page(menu)">
                        <img src="@/assets/semiDesign-semi-icons-chevron_right Copy 3@2x.png" alt="">
                        {{ menu.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="infoRight">
                <img src="@/assets/world@2x.png" alt="">
              </div>
            </div>
          </div>

        </div>
        <div class="footerBottom">
          <div class="bottomIn">
            <div class="bottomLeft">
              <div class="leftImg">
                <img src="@/assets/ear white@2x.png" alt="">
              </div>
              <div class="infoDetail">
                <div class="detailBox">
                  地址:浙江省杭州市东新路537号 热线电话:400-8899-485 (浙杭)网械企备字[2023]第01365号 <a href="https://beian.miit.gov.cn/#/Integrated/index" class="detailBox1">浙ICP备08000334号-3</a>

                </div>
                <div class="detailBox">
                  Copyright © 2014 <span style="color:#bae8aa;font-size: .875rem;">惠耳听力</span> All Rights Reserved.
                  技术支持:DBSI
                </div>
                <div class="detailBox">
                  <img src="@/assets/baidu@2x.png" alt="">
                  360网站安全检测平台
                </div>
              </div>
            </div>
            <div class="infoRight">
              <div class="iconBox">
                <div class="icon">
                  <img src="@/assets/antOutline-weibo Copy@2x.png" alt="">
                </div>
                <div class="icon">
                  <img src="@/assets/fab fa-tencent-weibo Copy@2x.png" alt="">
                </div>
                <div class="icon">
                  <img src="@/assets/riFill-qq-fill Copy@2x.png" alt="">
                </div>
                <div class="icon">
                  <img src="@/assets/fab fa-weixin Copy@2x.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>

    <!-- <el-dialog width="27%" :visible.sync="showLogin" title="登录">
      <el-form ref="dataForm" :rules="rules" :model="dataForm" status-icon label-width="50px">
        <el-form-item label="账号" prop="userName">
          <el-input v-model="dataForm.userName" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="dataForm.password" type="password" show-password/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" title="微信扫码登录">
        <el-button @click="wxLogin1()">微信扫码登录</el-button>
        <el-button @click="showLogin = false">取消</el-button>
        <el-button type="primary" @click="login">确定</el-button>
      </div>
    </el-dialog> -->
    

    <el-dialog width="920px" custom-class="center-dialog" :visible.sync="wxLogin">
        <div class="huierlogin">
          <div class="huierlogin-left" >
            <div class="huierlogo" style="display: flex; margin-left: 90px">
                <img src="./assets/ear@3x.png" style="width: 50px; height: 10%; margin-top: 20px;"  alt="">
                <div style="font-size: 30px; margin-top: 40px; margin-left: 40px; color: #019C3D;">惠耳听力官网</div>
            </div>
            <div class="background-img" style="display: flex; width: 75%; margin-left: 70px">
              <img src="./assets/login.png" alt="">
            </div>
          </div>  

          <div class="huierlogin-right" style="position: absolute; top:50px; right: 90px;">
            <el-card>
              <div id="login_container"></div>
              <div style="font-size: 10px;">温馨提示：当前普通用户仅支持扫码登录</div>
            </el-card>
          </div>
        </div>
      </el-dialog>

    <LeaveMessageDiaLog :showLeaveMessage="showLeaveMessage" @close="closeDialog"></LeaveMessageDiaLog>
  </div>
</template>

<script>
import { loginByUsername,loginByQRCode} from '@/api/mall';
import LeaveMessageDiaLog from '@/components/LeaveMessageDiaLog.vue';
import { setLocalStorage } from '@/utils/local-storage';
export default {
  beforeUpdate() {
    this.activeIndex = this.$route.path;
  },
  computed: {
    minWidth: function () {
      return (window.screen.width * 0.95) + 'px'
    },
  },
  components: { LeaveMessageDiaLog },
  data() {
    return {
      // wxInfoObject: {
      //   code: new URLSearchParams(window.location.search).get('code'),
      //   state: new URLSearchParams(window.location.search).get('state')
      // },
      nickName:'',
      rescode: '',
      activeIndex: "/", // 头部导航栏选中的标签
      search: "", // 搜索条件
      register: false, // 是否显示注册组件
      visible: false, // 是否退出登录
      showLogin: false,
      wxLogin:false,
      rules: {
        userName: [
          { required: true, message: '账号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ],
      },
      dataForm: {
        userName: '',
        password: '',
      },
      userInfo: {},
      //上层菜单
      menuData: [
        {
          name: '首页',
          url: '/',
          index: '0',
          pic: require('@/assets/home white@2x.png')
        },
        {
          name: '关于惠耳',
          children: [
            {
              name: '惠耳简介',
              url: '/HuierProfile',
            },
            {
              name: '企业文化',
              url: '/HuierCulture',
            },
            {
              name: '企业荣誉',
              url: '/enterpriseHonor',
            },
            // {
            //   name:'发展历程',
            //   url:'',
            // },
            {
              name: '科研成果',
              url: '/scientificPayoffs',
            },
            {
              name: '惠耳风采',
              url: '/HuierElegantDemeanor',
            },
            {
              name: '企业执照',
              url: '/enterpriseLicense',
            },
          ],
          url: '',
          index: '1',
          pic: require('@/assets/ear white@2x.png')
        },
        {
          name: '了解听力',
          children: [
            {
              name: '听力知识',
              url: '/hearingKnowledge',
            },
            {
              name: '聋儿康复',
              url: '/deafKidsRecovery',
            },
            {
              name: '助听器常识',
              url: '/aidsCommonSense',
            },
            {
              name: 'Q&A  问答',
              url: '/QuestionAndAnswer',
            },
            {
              name: '听力测试',
              url: '/listenTest',
            },
            {
              name: '典型案例',
              url: '/classicCase',
            },
          ],
          url: '',
          index: '2',
          pic: require('@/assets/question white@2x.png')
        },
        {
          name: '产品体系',
          url: '/mall',
          index: '3',
          pic: require('@/assets/mall white@2x.png')
        },
        {
          name: '听力顾问',
          children: [
            {
              name: '高级顾问咨询',
              url: '/expertConsult',
            },
            {
              name: '服务咨询',
              url: '/consultingOnline',
            },
            {
              name: '上门咨询',
              url: '/visitingService',
            },
            {
              name: '顾问咨询',
              url: '/visitingConsult',
            },
          ],
          url: '',
          index: '4',
          pic: require('@/assets/person white@2x.png')
        },
        {
          name: '服务支持',
          children: [
            {
              name: '听力中心',
              url: '/listeningCenter',
            },
            {
              name: '语训机构',
              url: '/trainingSetup',
            },
            {
              name: '专业服务项目',
              url: '/profServiceProject',
            },
            {
              name: '维护与保养',
              url: '/fixing',
            },
            // {
            //   name: '文档下载',
            //   url: '/download',
            // },
            {
              name: '联系我们',
              url: '/contactUs',
            },
          ],
          url: '',
          index: '5',
          pic: require('@/assets/Heart white@2x.png')
        },
        {
          name: '客户评价',
          url: '/customerAppraisement',
          index: '6',
          pic: require('@/assets/star white@2x.png')
        },
      ],
      footerData: [
        {
          name: '关于惠耳',
          children: [
            {
              name: '公司概况',
              url: '/HuierProfile',
            },
            {
              name: '历史和使命',
              url: '/HuierCulture',
            },
            {
              name: '惠耳风采',
              url: '/HuierElegantDemeanor',
            },
            {
              name: '创新科技',
              url: '/scientificPayoffs',
            },
          ],
          url: '',
          index: '1',
          pic: require('@/assets/ear white@2x.png')
        },
        {
          name: '了解听力',
          children: [
            {
              name: '听力知识',
              url: '/hearingKnowledge',
            },
            {
              name: '聋儿康复',
              url: '/deafKidsRecovery',
            },
            {
              name: '助听器常识',
              url: '/aidsCommonSense',
            },
            {
              name: 'Q&A  问答',
              url: '/QuestionAndAnswer',
            },
            {
              name: '听力测试',
              url: '/listenTest',
            },
            {
              name: '典型案例',
              url: '/classicCase',
            },
          ],
          url: '',
          index: '2',
          pic: require('@/assets/question white@2x.png')
        },
        {
          name: '助听器产品',
          children: [
            {
              name: '耳道式助听器',
              url: '',
            },
            {
              name: '耳内式助听器',
              url: '',
            },
            {
              name: '耳背式助听器',
              url: '',
            },
            {
              name: '盒式助听器',
              url: '',
            },
            {
              name: '特价专区',
              url: '',
            },
          ],
          url: '',
          index: '4',
          pic: require('@/assets/person white@2x.png')
        },
        {
          name: '听力顾问',
          children: [
            {
              name: '高级顾问咨询',
              url: '/expertConsult',
            },
            {
              name: '服务咨询',
              url: '/consultingOnline',
            },
            {
              name: '上门咨询',
              url: '/visitingService',
            },
            {
              name: '顾问咨询',
              url: '/visitingConsult',
            },
          ],
          url: '',
          index: '4',
          pic: require('@/assets/person white@2x.png')
        },
        {
          name: '服务支持',
          children: [
            {
              name: '听力中心',
              url: '/listeningCenter',
            },
            {
              name: '语训机构',
              url: '/trainingSetup',
            },
            {
              name: '专业服务项目',
              url: '/profServiceProject',
            },
            {
              name: '维护与保养',
              url: '/fixing',
            },
            // {
            //   name: '文档下载',
            //   url: '/download',
            // },
            {
              name: '联系我们',
              url: '/contactUs',
            },
          ],
          url: '',
          index: '5',
          pic: require('@/assets/Heart white@2x.png')
        },
        {
          name: '其他栏目',
          url: '',
          children: [
            {
              name: '新闻资讯',
              url: '/News',
            },
            // {
            //   name: '公司资讯',
            //   url: '/News',
            // },
            {
              name: '联系方式',
              url: '',
            },
            {
              name: '人才招募',
              url: '',
            },
            {
              name: '视频中心',
              url: '/listenTest',
            },
            {
              name: '法律声明',
              url: '',
            },
          ],
          index: '6',
          pic: require('@/assets/star white@2x.png')
        },
      ],

      //右侧栏咨询相关
      showLeaveMessage: false,  //顾问咨询弹窗 
      consultationOnlineURL: "https://affim.baidu.com/cps5/chatIndex?siteToken=42f719fd0583a31eabb95b543b5a0e76&speedLogId=1676339679003706e_1676339679003_93759&bid=2544313001056862690&eid=314689&reqParam=%7B%22from%22%3A0,%22sid%22%3A%22-100%22,%22tid%22%3A%22-1%22,%22ttype%22%3A1,%22siteId%22%3A%225686269%22,%22userId%22%3A%22314689%22,%22pageId%22%3A0%7D", //在线咨询需要跳转的外部连接
    };
  },
  mounted() {
    // this.setWxerwma()
    const code = this.getQueryVariable('code')
    const state = this.getQueryVariable('state')
    //获取localStorage中的用户名
    const nickName = localStorage.getItem("nickName")
    if (code != false && nickName == null) {
      this.wxLogin2(code,state)
      this.nickName = nickName
    }else{
      this.nickName = localStorage.getItem("nickName")
    }
  },
  methods: {
    wxLogin1(){
      this.wxLogin = true
      this.setWxerwma()
    },
    setWxerwma () {
      const s = document.createElement('script')
      console.log('s',s);
      s.type = 'text/javascript'
      s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      const wxElement = document.body.appendChild(s)
      wxElement.onload = function () {
        var obj = new WxLogin({
          id: 'login_container', // 需要显示的容器id
          appid: 'wx7655d5bad59e43ca', // 公众号appid wx*******
          scope: 'snsapi_login', // 网页默认即可
          redirect_uri: 'https://33huier.com', // 授权成功后回调的url
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: 'black', // 提供"black"、"white"可选。二维码的样式
          href: ''
        })
      }
    },
    wxLogin2(code,state){
      loginByQRCode(code,state).then(response => {
        this.userInfo = response.data.data.userInfo
        this.$message({
          type: 'success',
          message: '微信登录成功'
        })
        setLocalStorage({
          Authorization: response.data.data.token,
          userInfo: this.userInfo.avatarUrl,
          nickName: this.userInfo.nickName,
        })
        this.nickName = localStorage.getItem('nickName')
      }).catch(error => {
          this.$message({
              type:"error",
              message:error.data.errmsg
          })
        })
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      //把查询字符串按照&符号分割成数组
      var vars = query.split("&");
      //遍历数组中的每个元素
      for (var i = 0; i < vars.length; i++) {
        //把当前元素按照=符号分割成键和值
        var pair = vars[i].split("=");
        //如果当前元素的键等于参数名，则返回对应的值
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      //如果没有找到匹配的参数名，则返回false
      return false;
    },
    refresh(){
      location.reload()
    },
    router_page(menu) {
      this.$router.push({ path: menu.url })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    login({ commit }) {
      const username = this.dataForm.username
      return new Promise((resolve, reject) => {
        loginByUsername(this.dataForm.userName, this.dataForm.password, this.dataForm.code).then(response => {
          const token = response.data.data.token
          // console.log(response);
          // console.log(token);
          // commit('SET_TOKEN', token)
          // setToken(token)
          // console.log('user',response)
          this.userInfo = response.data.data.userInfo
          this.showLogin = false
          this.$message({
            type:"success",
            message:"登录成功"
          })
          setLocalStorage({
            Authorization: response.data.data.token,
            userInfo: this.userInfo.avatarUrl,
            nickName: this.userInfo.nickName
          });
          this.nickName = localStorage.getItem("nickName")
          this.refresh() //刷新页面
          resolve()
        }).catch(error => {
          // reject(error)
          // console.log(error);
          this.$message({
              type:"error",
              message:error.data.errmsg
          })
        })
      })
    },
    overShow() {
      document.getElementById('publicPic').style.display = 'block'
    },
    outHide() {
      document.getElementById('publicPic').style.display = 'none'
    },
    //小窗口跳转，屏幕居中显示
    openWindow(url, title) {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;

      const width = 815;
      const height = 617;

      const left = screenWidth / 2 - width / 2;
      const top = screenHeight / 2 - height / 2;
      const newWindow = window.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
      );

      if (window.focus) {
        newWindow.focus();
      }
    },

    //关闭咨询顾问对话框
    closeDialog() {
      this.showLeaveMessage = false
    },
    //返回顶部
    backToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style>
* {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

.containerBox {
  max-width: 120rem
    /* 1920/16 */
  ;
  margin: 0 auto;
}

.app {
  width: 100%;
}

.topBar {
  width: 70%;
  margin: 0 auto;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .625rem
    /* 10/16 */
  ;
}

.navLeft {
  width: 12.5rem
    /* 200/16 */
  ;
  height: 3.75rem
    /* 60/16 */
  ;
}

.navLeft img {
  width: 100%;
  height: 100%;
}

.navRight {
  width: 28.125rem
    /* 450/16 */
  ;
  /* height: 3.75rem */
    /* 60/16 */
    margin-top: 20px;
}

.rightTop {
  display: flex;
  justify-content: right;
  align-items: center;
}

.rightTop img {
  width: 1.375rem
    /* 22/16 */
  ;
  height: 1.375rem
    /* 22/16 */
  ;
}

.rightTop1 {
  display: flex;
  justify-content: right;
  align-items: center;
}

.rightTop1 img {
  width: 1.375rem
    /* 22/16 */
  ;
  height: 1.375rem
    /* 22/16 */
  ;
  margin-left: 30px;
}

.telTxt {
  width: 10.1875rem
    /* 163/16 */
  ;
  height: 1.25rem
    /* 20/16 */
  ;
  margin-left: .8125rem
    /* 13/16 */
  ;
  font-size: .875rem
    /* 14/16 */
  ;
}

.rightBottom {
  height: 2.25rem
    /* 36/16 */
  ;
  display: flex;
  align-items: center;
  justify-content: right;
  
}

.box {
  /* margin: 1rem */
    /* 16/16 */
    /* 31/16 */
    margin: 1.9rem
  ;
  font-size: .75rem
    /* 12/16 */
  ;
  width: fit-content;
  min-width: 50px;
  font-family: SourceHanSansSC-regular;
  font-weight: bold;
}

.box1:hover{
  cursor:pointer;
}

.searching {
  height: 2rem
    /* 32/16 */
  ;
}

.navBox {
  width: 14.28%;
  font-weight: bold;
  font-size: 1rem
    /* 16/16 */
    !important;
}

.navChildrenBox {
  font-weight: bold;
  text-align: center;
  font-size: .75rem
    /* 12/16 */
    !important;
  width: 100%;
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  width: 100% !important;
}

/*去除百度地图水印*/
.BMap_cpyCtrl {
  display: none;
}

.anchorBL {
  display: none;
}
</style>

<style lang="less" scoped>
/deep/.el-input--small .el-input__inner {
  width: 8.6875rem
    /* 139/16 */
  ;
  box-sizing: border-box;
}

/deep/.el-header {
  width: 100%;
}

/deep/.el-icon-arrow-down:before {
  color: white;
}

/deep/.el-header {
  height: auto !important;
}

.container {
  width: 70%;
  margin: 0 auto;
  padding-left: 1.5625rem
    /* 25/16 */
  ;
  text-align: left;
  box-sizing: border-box;
}

.breadCrumb {
  color: #9E9894;
  font-size: .875rem
    /* 14/16 */
  ;
  margin-bottom: 1rem
    /* 16/16 */
  ;
}

.breadCrumb .currentCrumb {
  color: #bababa;
}

a {
  text-decoration: none;
  color: rgba(27, 27, 27, 1);
}

/deep/.el-main {
  padding: 0 1.25rem
    /* 20/16 */
  ;
}

/deep/.el-submenu__title {
  font-size: 1rem
    /* 16/16 */
  ;
}

::v-deep .el-submenu__title #menuImg {
  width: 1.125rem
    /* 18/16 */
  ;
  height: 1.125rem
    /* 18/16 */
  ;
  margin-right: .3125rem
    /* 5/16 */
  ;
}

/deep/.el-menu-item * {
  width: 1.125rem
    /* 18/16 */
  ;
  height: 1.125rem
    /* 18/16 */
  ;
  margin-right: .3125rem
    /* 5/16 */
  ;
}

.footerTop {
  display: 100%;
  background-color: rgba(68, 108, 55, 0.72);
  color: rgba(255, 255, 255, 1);
  ;
}

.topIn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.footerInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 1.5rem
    /* 24/16 */
  ;
}

.infoLeft {
  width: 75%;
}

.infoRight {
  width: 25%;
  text-align: center;
}

.infoRight img {
  width: 14.4375rem
    /* 231/16 */
  ;
  height: 12.8125rem
    /* 205/16 */
  ;
}

.footerMenu {
  width: 100%;
  padding: 0 1.875rem
    /* 30/16 */
  ;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
}

.footerItem {
  widows: 16%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.itemTitle {
  font-size: 1.25rem
    /* 20/16 */
  ;
  font-family: SourceHanSansSC-regular;
  margin-bottom: 1.875rem
    /* 30/16 */
  ;
}

.itemList {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.listItem {
  margin-bottom: .3125rem
    /* 5/16 */
  ;
  font-size: .75rem
    /* 12/16 */
  ;
  cursor: pointer;
}

.listItem img {
  width: .5rem
    /* 8/16 */
  ;
  height: .5rem
    /* 8/16 */
  ;
}

.footerBottom {
  display: 100%;
  background-color: rgba(68, 108, 55, 0.72);
  color: rgba(255, 255, 255, 1);
  ;
}

.bottomIn {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bottomLeft {
  width: 75%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 3.125rem
    /* 50/16 */
    0;
}

.leftImg {
  width: 3.6875rem
    /* 59/16 */
  ;
  height: 3.6875rem
    /* 59/16 */
  ;
  margin-right: .6875rem
    /* 11/16 */
  ;
}

.leftImg img {
  width: 100%;
  height: 100%;
}

.infoDetail {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.detailBox {
  font-size: .75rem
    /* 12/16 */
  ;
  font-size: .875rem
    /* 14/16 */
  ;
  text-align: left;
  margin: .3125rem
    /* 5/16 */
  ;
}

.detailBox img {
  width: 1.1875rem
    /* 19/16 */
  ;
  height: 1.1875rem
    /* 19/16 */
  ;
}

.iconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3.125rem
    /* 50/16 */
    0;
}

.icon {
  background-color: rgba(77, 128, 71, 1);
  ;
  width: 2rem
    /* 32/16 */
  ;
  height: 2rem
    /* 32/16 */
  ;
  border-radius: 1rem
    /* 16/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .625rem
    /* 10/16 */
  ;
  cursor: pointer;
}

.icon img {
  width: 1.1875rem
    /* 19/16 */
  ;
  height: 1.1875rem
    /* 19/16 */
  ;
}

/deep/.el-footer {
  padding: 0;
}

/deep/.el-input__inner {
  height: 2rem
    /* 32/16 */
  ;
  line-height: 2rem
    /* 32/16 */
  ;
}

.rightNav {
  position: fixed;
  right: 1.875rem
    /* 30/16 */
  ;
  border-radius: .5rem
    /* 8/16 */
  ;

}

.rightNavBox {
  width: 4.375rem
    /* 70/16 */
  ;
  height: 5.25rem
    /* 84/16 */
  ;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(240, 240, 240, 1);
}

.rightNavBox:first-child {
  border-radius: .5rem
    /* 8/16 */
    .5rem
    /* 8/16 */
    0 0;
}

.rightNavBox:last-child {
  border-radius: 0 0 .5rem
    /* 8/16 */
    .5rem
    /* 8/16 */
  ;
}

.rightNavBox img {
  width: 1.625rem
    /* 26/16 */
  ;
  height: 1.625rem
    /* 26/16 */
  ;
  margin-bottom: .25rem
    /* 4/16 */
  ;
}

.rightNavBoxTitle {
  color: rgba(17, 17, 17, 0.86);
  font-size: .75rem
    /* 12/16 */
  ;
  text-align: center;
  font-family: SourceHanSansSC-regular;
  box-sizing: border-box;
  padding: 0 .3125rem
    /* 5/16 */
  ;
}

.rightNavBox:hover {
  background-color: rgba(77, 128, 71, 1);
}

.rightNavBox:hover .rightNavBoxTitle {
  color: rgba(255, 255, 255, 1);
}

#publicPic {
  position: fixed;
  right: 6.875rem
    /* 110/16 */
  ;
  width: 6.5rem
    /* 104/16 */
  ;
  height: 8.1875rem
    /* 131/16 */
  ;
  border-radius: .5rem
    /* 8/16 */
  ;
  background-color: rgba(77, 128, 71, 1);
  z-index: 9;
  top: 23.9375rem
    /* 383/16 */
  ;
}

.publicTitle {
  color: rgba(255, 255, 255, 1);
  font-size: .75rem
    /* 12/16 */
  ;
  font-family: SourceHanSansSC-regular;
  margin-bottom: .5rem
    /* 8/16 */
  ;
  padding-top: .5rem
    /* 8/16 */
  ;
  box-sizing: border-box;
}

#publicPic img {
  width: 5.125rem
    /* 82/16 */
  ;
  height: 4.9375rem
    /* 79/16 */
  ;
}

//顾问咨询表单
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapLeft {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapLeftResult {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.leftImg {
  width: 3.125rem
    /* 50/16 */
  ;
  height: 3.125rem
    /* 50/16 */
  ;
  margin-bottom: 1.5625rem
    /* 25/16 */
  ;
}

.leftImg img {
  width: 100%;
  height: 100%;
}

.leftWord {
  color: rgba(16, 16, 16, 1);
  font-size: 1.125rem
    /* 18/16 */
  ;
}

.wrapRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.publicImg {
  width: 7.625rem
    /* 122/16 */
  ;
  height: 8.625rem
    /* 138/16 */
  ;
  margin-bottom: 1rem
    /* 16/16 */
  ;
}

.publicImg img {
  width: 100%;
  height: 100%;
}

.publicContent {
  color: rgba(16, 16, 16, 1);
  font-size: 1rem
    /* 16/16 */
  ;
}

.detailBox1{
  color: #fff;
}
</style>
