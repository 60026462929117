import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cartTotal:0
  },
  getters: {

  },
  mutations: {
    getCartTotal(state,total){
      state.cartTotal = total
    }
  },
  actions: {
    GET_CARTOTAL({commit},total){
      commit('getCartTotal',total)
    }
  },
  modules: {
  }
})
