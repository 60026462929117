import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'

axios.defaults.withCredentials = true

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // config.headers = {
    //   'X-Requested-With': 'XMLHttpRequest',
    //   'Content-Type': 'application/json'
    // };
    // const token = JSON.parse(localStorage.getItem('token')); //这里取token之前，你肯定需要先拿到token,存一下
    // if (token) {
    //   //  config.params = {'token':token} //如果要求携带在参数中
    //   config.headers.Authorization = token; //如果要求携带在请求头中
    // }
    // if (store.getters.token) {
    //   // 让每个请求携带token-- ['X-Huiermall-Admin-Token']为自定义key 请根据实际情况自行修改
    //   config.headers['X-Huiermall-Admin-Token'] = getToken()
    // }
    if (!config.headers['X-Huiermall-Token']) {
      config.headers['X-Huiermall-Token'] = `${window.localStorage.getItem(
        'Authorization'
      ) || ''}`;
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)
let flag = false // 判断是否弹过提示弹窗
// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    if (res.errno === 501 && !flag) {
      MessageBox.alert('系统未登录，请重新登录', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      }).then(() => {
        // 清除token
        if (localStorage.getItem('Authorization')) {
          localStorage.removeItem('Authorization')
        }
        store.dispatch('FedLogOut').then(() => {
          location.reload()
        })
        flag = true
      })
      return Promise.reject('error')
    } else if (res.errno === 502) {
      MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno === 503) {
      MessageBox.alert('请求业务目前未支持', '警告', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno === 504) {
      MessageBox.alert('更新数据已经失效，请刷新页面重新操作', '警告', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno === 505) {
      MessageBox.alert('更新失败，请再尝试一次', '警告', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno === 506) {
      MessageBox.alert('没有操作权限，请联系管理员授权', '错误', {
        confirmButtonText: '确定',
        type: 'error'
      })
      return Promise.reject('error')
    } else if (res.errno !== 0) {
      // 非5xx的错误属于业务错误，留给具体页面处理
      return Promise.reject(response)
    } else {
      return response
    }
  }, error => {
    console.log('err' + error)// for debug
    Message({
      message: '登录连接超时（后台不能连接，请联系系统管理员）',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })

export default service
